import { ComponentStyleConfig } from "@chakra-ui/react";
import { animations } from "../styles/animations";

export const sizes = {
	xl: {
		fontSize: 'body',
		lineHeight: 'base',
		letterSpacing: 'wide',
		'.icon-container': {
			h: "5rem",
			w: "5rem",
			borderWidth: '2.5px',
		}
	},
	lg: {
		fontSize: 'body',
		lineHeight: 'base',
		letterSpacing: 'wide',
		'.icon-container': {
			h: "4rem",
			w: "4rem",
			borderWidth: '2.5px',
		}
	},
	md: {
		fontSize: 'xs',
		lineHeight: '1.125rem',
		letterSpacing: 'wider',
		'.icon-container': {
			h: "45px",
			w: "45px",
			borderWidth: '2px',
		},
	},
	sm: {
		fontSize: 'xs',
		lineHeight: 'base',
		letterSpacing: 'wider',
		'.icon-container': {
			h: "35px",
			w: "35px",
			borderWidth: '2px',
		},
	}
}

export const getLinkVariants = (isRtl: boolean) => ({
	'primary': {
		display: 'inline-flex',
		alignItems: 'center',
		'.icon-container': {
			flexShrink: "0",
			borderRadius: '50%',
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderColor: 'currentColor',
		},
		'&:hover, &:focus': {
			textDecoration: 'none',
		},
	},
	'secondary': {
		display: 'inline-flex',
		alignItems: 'center',
		'.icon-container': {
			flexShrink: "0",
		},
		'&:hover, &:focus': {
			textDecoration: 'none',
		},
	},
	'tertiary': {
		display: "inline",
		...animations(isRtl).underline,
	},
	'unstyled': {
		fontFamily: 'inherit',
		fontWeight: 'inherit',
		transition: "none",
		'&:hover': {
			textDecoration: "none"
		}
	},
	'dropdown': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: "space-between",
		borderRadius: "sm",
		px: "4",
		py: "3",
		color: "primary.text",
		transition: "none",
		'.icon-container': {
			ms: "4",
		},
		'svg': {
			fill: "currentColor"
		},
		'&:active, &:hover, &:focus': {
			bg: "white",
			textDecoration: 'none',
		},
	}
});

export type LinkSize = keyof typeof sizes | (keyof typeof sizes | null)[];
export type LinkVariant = keyof (ReturnType<typeof getLinkVariants>);

export const Link = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: 'body',
		fontWeight: '400',
		transition: "color .3s ease-in-out",
	},
	sizes,
	variants: getLinkVariants(isRtl),
	defaultProps: {
		variant: 'unstyled',
	},
});