import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
	field: {
		_placeholder: {
			color: "primary.aramcoGrey"
		},
	},
});

export const Input = defineMultiStyleConfig({ baseStyle })
