import React, { FC } from "react";
import { SitecoreContextValue } from "~/foundation/Jss";
import { Link as RouterLink } from "react-router-dom";

type ErrorPageProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
	context: SitecoreContextValue;
}

export const ErrorPage: FC<ErrorPageProps> = ({ context = { site: { name: "" }, language: "" }, error }) => (
	<>
		{console.error(error)}
		<h1>Error page</h1>
		<p>
			<RouterLink to="/">Go to home</RouterLink>
		</p>
		<p>
			Site: {context.site && context.site.name}
			<br />
			Language: {context.language}
		</p>
	</>
);