import React, { FC, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { MainNavigationItemModel, MainNavigationProps } from "../generated-types";
import { Flex, Box, useMediaQuery, HStack, Link as ChakraLink, useBreakpointValue } from "@chakra-ui/react";
import { SubNavigationStyling } from "./styles";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { SubNavigationLevel } from "./SubNavigationLevel";
import { Search } from "./Search";
import { ReactComponent as AramcoA } from "~/foundation/Assets/svg/graphics/a.svg";
import { useTranslation } from "~/foundation/Dictionary";
import { type NavigationDictionary } from "../dictionary";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { useEventEmitter } from "~/foundation/Events";
import { NavigationEvents } from "../events";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { withNavigationTheme } from "~/foundation/Theme/enhancers/withNavigationTheme";
import { AnimatePresence } from "framer-motion";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

type OpenMenuData = {
	level: number,
	menuItem: MainNavigationItemModel,
}

type OpenMenuDataActionAdd = {
	type: "ADD",
	payload: OpenMenuData
}

type OpenMenuDataActionSet = {
	type: "SET",
	payload: OpenMenuData[]
}

type OpenMenuDataActionWithoutPayload = {
	type: "CLEAR" | "REMOVE_LAST"
}

type OpenMenuDataAction = OpenMenuDataActionWithoutPayload | OpenMenuDataActionSet | OpenMenuDataActionAdd

function openMenuDataReducer(state: OpenMenuData[], action: OpenMenuDataAction) {
	switch (action.type) {
		case "ADD": {
			return [...state, action.payload];
		}
		case "SET": {
			return action.payload;
		}
		case "REMOVE_LAST": {
			state.length = state.length - 1;
			return [...state];
		}
		case "CLEAR": {
			return [];
		}
		default: {
			return state;
		}
	}
}

type SubNavigationProps = {
	navChildren: MainNavigationItemModel[] | undefined,
	disclosureProps: any, // eslint-disable-line
	activeNavChildren: MainNavigationItemModel[] | undefined,
	isOpen: boolean,
	handleFirstLevelClick: (menuItem: MainNavigationItemModel | undefined) => void,
	rendering: MainNavigationProps["rendering"],
	navRef: React.RefObject<HTMLDivElement>
}

const ancestorOrSelfRouteArray: {level: number; menuItem: MainNavigationItemModel}[] = [];
const animationDelay = 350;

const SubNavigation: FC<SubNavigationProps> = ({ navChildren, activeNavChildren, disclosureProps, isOpen , handleFirstLevelClick, rendering, navRef }) => {
	const [openLevel, setOpenLevel] = useState<number>(0);
	const [hasAncestorOrSelfActive, setHasAncestorOrSelfActive] = useState<boolean>(false);
	const [openMenuData, dispatchOpenMenuData] = useReducer(openMenuDataReducer, []);
	const [smallDeviceBgHeight, setSmallDeviceBgHeight] = useState("95dvh")

	const closeButtonsRef = useRef<NodeListOf<HTMLButtonElement> | null>(null);
	const subNavsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);

	const location = useLocation();
	const eventEmitter = useEventEmitter<NavigationEvents>("navigation");

	const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [theme] = useContext(ColorThemeContext);

	const isLargeViewport = useBreakpointValue({ base: false, lg: true }, { ssr: true });

	const [t] = useTranslation<NavigationDictionary>();

	useEffect(() => {
		setSmallDeviceBgHeight(`${Math.floor(window.innerHeight)}px`);
	}, []);

	useEffect(() => {
		if (navChildren && navChildren.length > 0) {
			setHasAncestorOrSelfActive(navChildren.some(item => item.isAncestorOrSelf))
		}

	}, [navChildren]);

	useEffect(() => {
		window.addEventListener("keyup", keyUp);

		return () => {
			window.removeEventListener("keyup", keyUp)
		}
	}, [isOpen]);

	useEffect(() => {
		if (navRef.current && openMenuData.length > 0) {
			closeButtonsRef.current = navRef.current.querySelectorAll<HTMLButtonElement>(".closebutton");
			subNavsRef.current = navRef.current.querySelectorAll<HTMLDivElement>(".subnav__block");

			closeButtonsRef.current.forEach(button => {
				button.classList.remove("show");
			});

			const activeSubNavs = Array.from(subNavsRef.current).filter(x => x.getAttribute("aria-expanded") === "true");
			const latestSubNav = activeSubNavs[activeSubNavs.length - 1];

			if (latestSubNav) {
				const closebutton = latestSubNav.querySelector<HTMLButtonElement>(".closebutton");

				if (closebutton) {
					closebutton.classList.add("show");
				}
			}
		}
	}, [openMenuData])

	useEffect(() => {
		closeSubNav();
	}, [location]);

	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

	const keyUp = (e: KeyboardEvent) => {
		if (e.key === "Escape" && isOpen) {
			closeSubNav();
		}
	}

	const findActiveAncestorOrSelf = (menuItems: MainNavigationItemModel[]) => {
		let updateState = true;

		menuItems?.forEach(menuItem => {
			if (menuItem.isAncestorOrSelf) {
				ancestorOrSelfRouteArray.push({ level: ancestorOrSelfRouteArray.length, menuItem: menuItem })

				if (menuItem.menuItemChildren && menuItem.menuItemChildren.length > 0) {
					findActiveAncestorOrSelf(menuItem.menuItemChildren)

					updateState = false;
				}
			}
		})

		if (!updateState) {
			return;
		}

		if (ancestorOrSelfRouteArray.length > 0) {
			dispatchOpenMenuData({ type: "SET", payload: ancestorOrSelfRouteArray });
		}
	}

	const openSubNavigation = (menuItem: MainNavigationItemModel) => {
		if (activeNavChildren === menuItem.menuItemChildren && isOpen) {
			closeSubNav();
			return;
		}

		if (hasAncestorOrSelfActive && navChildren) {
			findActiveAncestorOrSelf(navChildren);

			// Resetting the route active state since its purpose has been fulfilled
			setHasAncestorOrSelfActive(false);
		}

		if (ancestorOrSelfRouteArray.length === 0 || ancestorOrSelfRouteArray[0].menuItem !== menuItem) {
			dispatchOpenMenuData({ type: "SET", payload: [{ level: 0, menuItem }] });
			handleLevelChange(0, menuItem);
			handleFirstLevelClick(menuItem);
		} else {
			setOpenLevel(ancestorOrSelfRouteArray.length - 1);
			handleFirstLevelClick(ancestorOrSelfRouteArray[0].menuItem);
		}
	}

	const openSubNavigationMobile = () => {
		if (hasAncestorOrSelfActive && navChildren) {
			findActiveAncestorOrSelf(navChildren);

			// Resetting the route active state since its purpose has been used
			setHasAncestorOrSelfActive(false);
		}

		if (ancestorOrSelfRouteArray.length === 0) {
			handleLevelChange(0, null);
			handleFirstLevelClick(undefined);
		} else {
			setOpenLevel(ancestorOrSelfRouteArray.length - 1);
			handleFirstLevelClick(ancestorOrSelfRouteArray[0].menuItem);
		}
	}

	const addDataLayerTracking = (menuItem: MainNavigationItemModel) => {
		pushDataLayer(() => ({
			event: "GAevent",
			event_type: "click",
			event_name: "main_navigation",
			type: isLgBreakpointOrAbove ? "desktop" : "mobile",
			url: menuItem.url,
			text: menuItem.title,
			item_level: 0,
		}));
	}

	const closeSubNav = () => {
		const { onClose } = disclosureProps;
		onClose();

		if (openMenuData.length) {
			for (const ref of refsById) {
				if (ref.current?.innerText.toLowerCase() === openMenuData[0].menuItem.title.toLowerCase()) {
					ref.current.focus();
					break;
				}
			}
		}

		// Telling the header to render the theme logo
		eventEmitter.emit("onNavigationOpen", false);

		ancestorOrSelfRouteArray.length = 0;

		// Resetting the navigation when closing
		setOpenLevel(0);
		dispatchOpenMenuData({ type: "CLEAR" });
	}

	const handleLevelChange = (level: number, menuItem: MainNavigationItemModel | null) => {
		const openMenuDataMutation = [...openMenuData];

		// If we are clicking on an item already in the active object array
		if (openMenuDataMutation.some(item => item.menuItem === menuItem)) {
			return;
		}

		setOpenLevel(level);

		// Getting the difference between the highest active level and the clicked level for later use
		const diffCount = openMenuDataMutation.length - level;

		if (openMenuDataMutation.length > level) {
			let i = 0;
			let animationInterval: any = null; // eslint-disable-line

			const animationIntervalCallback = () => {
				// If the length of our array is different that the diffCount + 1 (to close the correct number of levels)
				if (i !== diffCount && openMenuDataMutation.length > 0) {
					openMenuDataMutation.length = openMenuDataMutation.length - 1;
				}

				// Updating the state to reflect changes in the SubNavigationLevel components
				dispatchOpenMenuData({ type: "SET", payload: [...openMenuDataMutation] });

				// If we reached the end of our loop we will stop and update state accordingly
				if (i === diffCount) {
					if (animationInterval) {
						clearInterval(animationInterval);
					}

					if (menuItem) {
						openMenuDataMutation.forEach(activeItem => {
							// If clicking is on an item of an already existing level
							if (activeItem.level === level) {
								activeItem.menuItem = menuItem
							}
						});

						dispatchOpenMenuData({ type: "SET", payload: [...openMenuDataMutation, { level, menuItem }] });
					}
				}
				i++;
			}

			// First state update to avoid the interval delay
			animationIntervalCallback();

			// Only if we are showing the desktop navigation we might need to close more levels than one at a time
			if (isLgBreakpointOrAbove) {
				// The rest of the state updates to take the delay into account
				animationInterval = setInterval(animationIntervalCallback, animationDelay);
			}

		} else {
			// If we add an object to the active array by navigating to the next level
			if (menuItem) {
				dispatchOpenMenuData({ type: "SET", payload: [...openMenuDataMutation, { level, menuItem }] });
			}
		}

		if (ancestorOrSelfRouteArray.length > 0) {
			ancestorOrSelfRouteArray.length = 0;
		}
	}

	const motionVariants = {
		open: { opacity: 1, transform: "translateX(0%)" },
		closed: { opacity: 0, transform: isRtl ? "translateX(-100vw)" : "translateX(100vw)" },
	};

	const transition = {
		default: {
			ease: [.35,1,.45,1],
			duration: .125,
		}
	}

	const refsById = useMemo(() => {
		const refs: React.RefObject<HTMLElement>[] = [];
		navChildren?.forEach(() => {
			refs.push(React.createRef());
		})
		return refs;
	}, [navChildren])

	const ariaAttributes = (menuItem: MainNavigationItemModel) => {
		return {
			"aria-controls": menuItem.pageId
		}
	}

	return (
		<>
			{isLargeViewport &&
				<HStack as="ul" mb="5" mt="14" gap="4" display="flex" aria-label="home">
					{navChildren && navChildren.map((menuItem, index) => {
						const isActive: boolean = (openMenuData.some(item => item.menuItem === menuItem) || menuItem.isAncestorOrSelf);

						if (!menuItem.menuItemChildren || menuItem.menuItemChildren.length === 0) {
							return (
								<Box
									as="li"
									key={index}
									listStyleType="none"
									overflow="hidden"
									color={theme.colors.header.color}
								>
									<RouterLink
										className={`mainnav__item${isActive
											? " mainnav__item--active"
											: (isOpen ? " mainnav__item--open" : "")}`}
										ref={refsById[index] as never}
										onClick={() => {
											closeSubNav();
											addDataLayerTracking(menuItem);
										}}
										to={menuItem.url}
									>
										{menuItem.title}
									</RouterLink>
								</Box>
							);
						}

						return (
							<Box as="li" key={index} listStyleType="none" overflow="hidden">
								<Box
									as="button"
									className={`mainnav__item ${
										(openMenuData.some(item => item.menuItem === menuItem) || menuItem.isAncestorOrSelf)
											? "mainnav__item--active"
											: (isOpen ? "mainnav__item--open" : "")}`}
									color={theme.colors.header.color}
									onClick={() => openSubNavigation(menuItem)}
									ref={refsById[index] as never}
									{...(isOpen && { ...ariaAttributes(menuItem) })}
								>
									{menuItem.title}
								</Box>
							</Box>
						)
					})}
				</HStack>
			}
			{(navChildren && !isLgBreakpointOrAbove) &&
				<Box
					as="button"
					type="button"
					role="button"
					aria-label="Mobile Navigation Button"
					aria-expanded={isOpen}
					gap=".375rem"
					pt="3"
					w="6"
					display={["flex", null, null, "none"]}
					flexDir="column"
					justifyContent="flex-end"
					onClick={openSubNavigationMobile}
				>
					<Box as="span" display="block" h="2px" w="100%" borderRadius="2px" bg={theme.colors.header.burgerMenuColor} />
					<Box as="span" display="block" h="2px" w="100%" borderRadius="2px" bg={theme.colors.header.burgerMenuColor} />
				</Box>
			}
			<AnimatePresence>
				{isOpen &&
					<MotionBox
						animate={isOpen ? "open" : "closed"}
						variants={motionVariants}
						initial="closed"
						transition={transition}
						className="subnav__wrapper"
						{...SubNavigationStyling(isRtl)}
					>
						{isLargeViewport && <Box position="absolute" h="100%" w="100%" onClick={() => closeSubNav()} />}
						<ContentWrapper
							px="0"
							py="0"
							h="100%"
							maxW={[null, null, "contentWrapperWidthMD", "contentWrapperWidthXL", null, "contentWrapperWidth2XL"]}>
							<Flex
								className="subnav__inner"
								height={[smallDeviceBgHeight, null, null, null]}
								{...disclosureProps}>
								{(navChildren && isLgBreakpointOrAbove) && navChildren.map((subNavItem, ix) => (
									<SubNavigationLevel
										index={0}
										key={ix}
										parentItem={subNavItem}
										menuItems={subNavItem.menuItemChildren}
										open={openMenuData.some(item => item.menuItem === subNavItem)}
										disclosureProps={disclosureProps}
										closeSubNav={closeSubNav}
										handleLevelChange={handleLevelChange}
										activeIndex={openLevel}
										activeMenuData={openMenuData}
										isLgBreakpointOrAbove={isLgBreakpointOrAbove}
									/>
								))}
								{(navChildren && !isLgBreakpointOrAbove) &&
									<Flex>
										<Flex position="absolute" px="var(--chakra-sizes-pageMarginSM)" pt="8" zIndex="1" w="100%">
											<Flex flexBasis="70%">
												<Search mobileView={true} />
											</Flex>
											<ChakraLink href={`/${sitecoreContext.language}`} display="flex" flexBasis="30%" alignItems="center" justifyContent="flex-end" color="primary.neutralGrey">
												<AramcoA fill="currentColor"/>
												<Box as="span" ms={3}>{t("navigation.mainNavigation.home")}</Box>
											</ChakraLink>
										</Flex>
										<SubNavigationLevel
											index={0}
											parentItem={undefined}
											menuItems={navChildren}
											open={true}
											disclosureProps={disclosureProps}
											closeSubNav={closeSubNav}
											handleLevelChange={handleLevelChange}
											activeIndex={openLevel}
											activeMenuData={openMenuData}
											isLgBreakpointOrAbove={isLgBreakpointOrAbove}
										/>
										<Box position="absolute" bottom="10" px="var(--chakra-sizes-pageMarginSM)" className="mobile-hygiene" w="100%">
											<sc.Placeholder name={rendering.placeholderNames.mobile_hygiene_navigation} rendering={rendering} />
										</Box>
									</Flex>
								}
							</Flex>
						</ContentWrapper>
					</MotionBox>
				}
			</AnimatePresence>
		</>
	);
};

export default withNavigationTheme({ fallbackToPageTheme: true })(SubNavigation);