import { ColorTheme } from "~/foundation/Theme";

export const AudioPlayerStyling = (isRtl: boolean, theme: ColorTheme) => ({
	"sx": {
		"input[type='range']": {
			appearance: "none",
			background: "transparent",
			cursor: "pointer",
			height: "2.1875rem",
			"--range-progress": 0,

			"&:before, &:after": {
				content: "''",
				position: "absolute",
				left: isRtl ? "auto" : "0",
				right: isRtl ? "0" : "auto",
				height: "1px",
				top: "50%",
				transform: "translateY(-50%)",
			},

			"&:before": {
				zIndex: 2,
				transitionDuration: ".25s",
				transitionProperty: "width",
				transitionTimingFunction: "aramcoCubic",
				bg: "gradients.progress",
				width: "var(--range-progress)",
			},

			"&:after": {
				bg: "primary.aramcoGrey",
				opacity: "0.4",
				width: "calc(100% + 35px)",
			},
		},

		"button": {
			"&:hover": {
				"svg": {
					color: theme.colors.header.activeColor
				}
			}
		},

		".audio-player": {
			"&__close-icon": {
				opacity: [1, null, null, 0],
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)"
			},

			"&__headphone-icon": {
				transitionDelay: ".125s",
				opacity: [0, null, null, 1],
			},

			"&__icon-wrapper": {
				cursor: "pointer",

				"svg": {
					transitionProperty: "opacity",
					transitionDuration: ".25s",
					transitionTimingFunction: "aramcoCubic"
				},

				"&:hover": {
					".audio-player__headphone-icon": {
						opacity: 0,
						transitionDelay: "0s"
					},

					".audio-player__close-icon": {
						opacity: 1,
						transitionDelay: ".125s"
					},
				}
			}
		}
	}
})