import React, { FC } from "react";
import { Tooltip, TooltipProps } from "@chakra-ui/react";

type CustomTooltipProps = {
	children: React.ReactNode;
	label: string
}

export const CustomTooltip: FC<CustomTooltipProps & TooltipProps> = ({ children, label, ...props }) => {
	return (
		<Tooltip
			{...props}
			hasArrow
			placement="top"
			label={label}
			bgColor="primary.darkGreen"
			color="primary.white"
			px=".625rem"
			py=".5rem"
			borderRadius="xs"
		>
			{children}
		</Tooltip>
	)
}