import React, { FC, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";

const animationTiming = 250;

type PageLoaderProps = {
	isFetchingRoute: boolean;
	children?: React.ReactNode;
}

export const PageLoader: FC<PageLoaderProps> = ({ isFetchingRoute, children }) => {
	const [showChildren, setShowChildren] = useState(true);

	useEffect(() => {
		if (!isFetchingRoute) {
			setShowChildren(false)

			setTimeout(() => {
				setShowChildren(true);
			}, animationTiming)
		} else {
			setShowChildren(false)
		}
	}, [isFetchingRoute])

	return (
		<Box className={`animate-page-wrapper${showChildren && !isFetchingRoute ? "" : " is-loading"}`}>
			{children}
		</Box>
	);
};