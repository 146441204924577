export const LanguageNavigationStyles = {
	"sx": {
		".mobile-hygiene &": {
			".language-nav__item-splitter": {
				px: ["7", null, null, "2"]
			}
		},
		".language-nav__item": {
			transitionDuration: ".750s",
			transitionTimingFunction: "ease-in-out",
			transitionProperty: "color",
			fontWeight: '400',
			lineHeight: 'base',

			"&--en": {
				fontFamily: `'Ghawar', sans-serif`,
				fontSize: ".75rem",
				letterSpacing: 'wider',
			},

			"&--ar": {
				fontFamily: `'Haradh', sans-serif`,
				fontSize: "1rem",
				letterSpacing: "0"
			},

			"&:hover, &:focus": {
				color: "primary.aramcoBlue",
			},
		}
	}
}