import React from "react";
import { Flex, FlexProps, IconProps } from "@chakra-ui/react";
import { Icon, IconType } from "../../Icon";
import { LinkFieldValue } from "~/foundation/Jss";

export type LinkIconWrapperProps = FlexProps & {
	type?: IconType;
	rtl?: boolean,
	w?: IconProps["w"],
	linkValue?: LinkFieldValue,
};


export const LinkIconWrapper = ({
	type,
	rtl,
	w,
	linkValue,
	...LinkIconWrapperProps
}: LinkIconWrapperProps) => {

	if (!type && linkValue) {
		switch (linkValue?.linkType || linkValue?.linktype) {
			case "media":
				type = "Download";
				break;
			case "external":
				type = "ExternalLink";
				break;
			case "internal":
				type = linkValue.target === "_blank" ? "ExternalLink" : "ArrowRight";
				break;
			default:
				type = "ArrowRight";
		}
	}

	if (!type){
		type = "ArrowRight"
	}

	const transforms = {
		horizontal: {
			start: "translateX(-150%)",
			end: "translateX(150%)",
		},
		vertical: {
			start: "translateY(-150%)",
			end: "translateY(150%)",
		},
		diagonal: {
			start: "translate3d(-100%, 100%, 0)",
			end: "translate3d(100%, -100%, 0)",
		},
	}

	let transformAxis;
	switch (type) {
		case "Download":
			transformAxis = transforms.vertical;
			break;
		case "ExternalLink":
			transformAxis = transforms.diagonal;
			break;
		default:
			transformAxis = transforms.horizontal;
	}

	return (
		<Flex
			className="icon-wrapper"
			position="relative"
			overflow="hidden"
			flexShrink="0"
			{...(rtl && { transform: "scaleX(-1)" })}
			{...LinkIconWrapperProps}
		>
			<Icon
				variant={type}
				w={w}
				fill="currentColor"
				position="absolute"
				transform={transformAxis.start}
				transition="transform .5s ease-out"
				//_groupHover is not used here since not working within nested group e.g. heroCarousel
				sx={{
					'a:hover &': {
						transform: 'translateX(0)'
					}
				}}
			/>
			<Icon
				variant={type}
				w={w}
				fill="currentColor"
				transition="transform .5s ease-out"
				//_groupHover is not used here since not working within nested group e.g. heroCarousel
				sx={{
					'a:hover &': {
						transform: transformAxis.end
					}
				}}
			/>
		</Flex>
	);
};

export default LinkIconWrapper;
