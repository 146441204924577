import React, { FC } from "react";
// eslint-disable-next-line no-restricted-imports
import { Image as ChakraImage, ImageProps as ChakraImageProps } from "@chakra-ui/react";

type LazyImageProps = Omit<ChakraImageProps, "loading" | "onLoad">;

export const LazyImage: FC<LazyImageProps> = (props) => {
	return (
		<ChakraImage {...props} loading="lazy" onLoad={(e) => {
			(e.target as HTMLImageElement).classList.add("image-loaded");
		}}/>
	)
}