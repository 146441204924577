import React, { FC } from "react";
import { Schema } from "~/foundation/Tracking/Schema";
import { RouteData, SitecoreContextValue } from "~/foundation/Jss";
import { ArticlePage } from "../generated-types";

type AffiliateSiteHomeSchema = {
	route: RouteData<ArticlePage>;
	context: SitecoreContextValue;
	resolveImageUrl: (imagePath?: string) => string | null;
}

export const AffiliateSiteHomeSchema: FC<AffiliateSiteHomeSchema> = ({ route, context, resolveImageUrl }) => {
	const offices = context.custom.settings.offices || [];

	return (
		<Schema schema={{
			"@context": "https://schema.org",
			"@type": "WebPage",
			"@id": `${context.custom.absoluteUrl}#WebPage`,
			url: context.custom.absoluteUrl,
			name: context.custom.settings.siteName || route.fields?.pageTitle?.value || route.fields?.metaTitle?.value || "Saudi Aramco",
			isPartOf: {
				"@type": "WebSite",
				"@id": `${context.custom.absoluteUrl}#Website`,
				name: "Aramco",
				url: context.custom.absoluteUrl,
				...(context.custom.settings.searchResultPageUrl && {
					potentialAction: {
						"@type": "SearchAction",
						target: `${context.custom.hostname}${context.custom.settings.searchResultPageUrl}?query={search_term_string}`,
						"query-input": "required name=search_term_string"
					}
				}),
				publisher: [{
					"@type": "Organization",
					"@id": `${context.custom.hostname}#Organization`,
					alternateName: "Saudi Aramco",
					description: "Aramco is one of the world's largest integrated energy and chemicals companies, creating value across the hydrocarbon chain, and delivering societal and economic benefits to people and communities around the globe who rely on the vital energy we supply.", // todo
					logo: {
						"@type": "ImageObject",
						url: resolveImageUrl(context.custom.settings.logoLight),
					},
					url: context.custom.hostname,
					department: [
						...offices.map(office => ({
							"@type": "LocalBusiness",
							"@id": office.primaryUrl + "#LocalBusiness",
							url: office.primaryUrl!,
							name: office.title,
							address: {
								"@type": "PostalAddress",
								streetAddress: office.address,
								postalCode: office.zip,
								addressRegion: office.region,
								addressLocality: office.city,
								addressCountry: office.country
							}
						}))],
					sameAs: [
						...context.custom.settings.socialMediaLinks,
						"https://en.wikipedia.org/wiki/Saudi_Aramco",
						"https://www.wikidata.org/wiki/Q679322",
						"https://www.theguardian.com/business/aramco"
					],
				}]
			}
		}} />
	);
}