import React, { FC, createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export type SearchQueryString = {
	topics?: string;
	query?: string;
	pubstart?: string;
	pubend?: string;
	sort?: "pubdate" | "score",
	page?: string;
	contentType?: string;
	multisite?: "true" | "false";
};

type SearchContextData = {
	facets: SearchQueryString;
	totalResultsCount: number;
	setTotalResultsCounts: React.Dispatch<React.SetStateAction<number>>;
}

export const SearchContext = createContext<SearchContextData>({ facets: {} } as any); // eslint-disable-line @typescript-eslint/no-explicit-any

type SearchProviderProps = {
	children: React.ReactNode;
	active: boolean;
}

export const SearchProvider: FC<SearchProviderProps> = ({ children, active }) => {
	const [facets, setFacets] = useState<SearchQueryString>({});
	const [totalResultsCount, setTotalResultsCounts] = useState<number>(0);
	const location = useLocation();

	useEffect(() => {
		if (active) {
			const urlParams = new URLSearchParams(location.search);
			const params = Object.fromEntries(urlParams);
			setFacets(params);
		}
	}, [location, active]);

	return (
		<SearchContext.Provider value={{ facets, setTotalResultsCounts, totalResultsCount }}>
			{children}
		</SearchContext.Provider>
	);
};