import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ReactComponent as APattern } from "~/foundation/Assets/svg/graphics/a-pattern.svg";
import { ReactComponent as AWordMark } from "~/foundation/Assets/svg/graphics/a-wordmark.svg";
import { Icon as ChakraIcon } from "@chakra-ui/icon";
import { ContentWrapper } from "../ContentWrapper";
import { Ribbon } from "../Ribbon";
import { useParallax } from "react-scroll-parallax";
import { debounce } from "~/foundation/Utils/debounce";

export const PageGraphics = () => {
	const [pattern, setPattern] = useState(false);
	const [wordMark, setWordMark] = useState(false);

	useEffect(() => {
		const container = document.querySelector(".container") as HTMLElement;
		const containerHeight = container.clientHeight;

		setPattern(containerHeight > 7000)
		setWordMark(containerHeight > 800)

		const resizeObserver = new ResizeObserver(
			debounce(() => {
				const containerHeight = container.clientHeight;
				setPattern(containerHeight > 7000);
				setWordMark(containerHeight > 800);
			}, 500)
		)

		resizeObserver.observe(container);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<Ribbon>
			{wordMark && <WordMark />}
			{pattern && <Pattern />}
		</Ribbon>
	);
}


const WordMark = () => {
	const wordMarkParallax = useParallax<HTMLDivElement>({
		speed: -30,
	});

	return (
		<Box
			ref={wordMarkParallax.ref}
			position="absolute"
			top={["calc(var(--chakra-sizes-headerHeightBase) + 450px)", null, null, "calc(var(--chakra-sizes-headerHeightLG) + 450px)"]}
			w="100%"
			zIndex="-1">
			<ContentWrapper px={['calc(var(--chakra-sizes-pageMarginSM) / 2 )', null, "4", null, '8']}>
				<Flex justifyContent="flex-start">
					<ChakraIcon as={AWordMark} w="72.6%" h="auto" opacity=".05"/>
				</Flex>
			</ContentWrapper>
		</Box>
	)
}

const Pattern = () => {
	const patternParallax = useParallax<HTMLDivElement>({
		speed: 30,
	});

	return (
		<Box ref={patternParallax.ref} position="absolute" bottom="1080px" w="100%" zIndex="-1">
			<ContentWrapper px={['calc(var(--chakra-sizes-pageMarginSM) / 2 )', null, "4", null, '8']}>
				<Flex justifyContent="flex-end">
					<ChakraIcon as={APattern} w="62%" h="auto" opacity=".2"/>
				</Flex>
			</ContentWrapper>
		</Box>
	)
}