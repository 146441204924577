export function googleTagManagerDeniedStorageScriptTag(googleGrantFunctionalityStorage = true, shouldNotRender = false) {
	if (shouldNotRender) {
		return "";
	}

	return `function gtag(){ dataLayer.push(arguments); } gtag('consent', 'default', { 'ad_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied', 'analytics_storage': 'denied', functionality_storage: '${googleGrantFunctionalityStorage ? 'granted' : 'denied'}', 'wait_for_update': 500 }); gtag('set', 'ads_data_redaction', true);`;
}

export function googleTagManagerScriptTag(googleAnalyticsId: string, shouldNotRender = false) {
	if (shouldNotRender) {
		return "";
	}

	return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${googleAnalyticsId}');`;
}

export function googleTagManagerIframe(googleAnalyticsId: string, shouldNotRender = false) {
	if (shouldNotRender) {
		return "";
	}

	return `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleAnalyticsId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
}

export function pushDataLayer(dataLayerFn: () => GTMDataLayer) {
	if (typeof window !== "undefined" && typeof dataLayerFn === "function" && window.dataLayer && Array.isArray(window.dataLayer)) {

		const dataLayer = dataLayerFn();

		if (dataLayer.event_type === "click") {
			window.dataLayer.push({
				event_name: "",
				id: "",
				type: "",
				text: "",
				url: "",
				count: "",
				index: "",
				group: "",
				item_level: "",
				breadcrumb: "",
				file_type: "",
				...dataLayer
			});
		} else {
			window.dataLayer.push(dataLayer);
		}

	}
}