/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { isEditorActive } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line
import LazyHydrate from "react-lazy-hydration";
import { Rendering } from "../Rendering";

type RenderingProps = {
	rendering: Rendering<unknown>
}

export const lazyHydrate = <P extends object>(InputComponent: React.ComponentType<P>) => {
	const hydrate = (props: P & RenderingProps) => {
		if (process.env.STORYBOOK || isEditorActive()) {
			return <InputComponent { ...props } />;
		}

		if (props.rendering?.lazyHydrate === false) {
			return <InputComponent { ...props } />;
		}

		return (
			// overriding style prop from LazyHydrate to avoid "display: contents"
			<LazyHydrate whenVisible style={{}} data-rendering={InputComponent.displayName}>
				<InputComponent {...props} />
			</LazyHydrate>
		);
	}

	hydrate.displayName = "hydrate";

	return hydrate;
}