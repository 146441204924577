import React, { FC, KeyboardEventHandler, useRef } from "react";
import { MainNavigationItemModel } from "../generated-types";
import { Box, CloseButton, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useSitecoreContext } from "~/foundation/Jss";
import { useTranslation } from "~/foundation/Dictionary";
import type { NavigationDictionary } from "../dictionary";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { Icon } from "~/foundation/Components/Icon";
import { Link as RouterLink } from "react-router-dom";
import { navBoxBgStyling } from "./styles";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";

type SubNavigationLevelProps = {
	menuItems: MainNavigationItemModel[] | undefined,
	index: number,
	parentItem: MainNavigationItemModel | undefined,
	open: boolean,
	disclosureProps: any, // eslint-disable-line
	closeSubNav: () => void,
	handleLevelChange: (level: number, menuItem: MainNavigationItemModel | null) => void,
	activeIndex: number,
	activeMenuData: {level: number; menuItem: MainNavigationItemModel}[],
	isLgBreakpointOrAbove: boolean
}

export const SubNavigationLevel: FC<SubNavigationLevelProps> = (
	{
		menuItems,
		index,
		parentItem,
		open,
		disclosureProps,
		handleLevelChange,
		closeSubNav,
		activeIndex,
		activeMenuData,
		isLgBreakpointOrAbove
	}) => {
	const parentRef = useRef<HTMLAnchorElement>(null);

	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const isSmallViewport = useBreakpointValue({ base: true, lg: false }, { ssr: true });

	const [t] = useTranslation<NavigationDictionary>();

	const motionVariants = {
		open: { opacity: 1, x: 0, display: "flex", flexShrink: 1, flexBasis: isLgBreakpointOrAbove ? "25%" : "100%" },
		closed: { opacity: 0, flexShrink: 0, x: (!isLgBreakpointOrAbove && !isRtl || isLgBreakpointOrAbove && isRtl) ? "100%" : "-100%",
			transitionEnd: {
				display: "none",
				opacity: 0,
			}
		},
	}

	const transition = {
		default: {
			ease: [.35,1,.45,1],
			duration: .35
		}
	}

	const openNextSubNavLevel = (menuItem: MainNavigationItemModel) => {
		if (index !== null) {
			handleLevelChange(index + 1, menuItem);
		}
	}

	const navigateBack = () => {
		if (index !== null) {
			handleLevelChange(index - 1, null);
		}
	}

	const onKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (e) => {
		if (e.shiftKey && e.key === "Tab") {
			e.preventDefault();

			const parentElement: HTMLAnchorElement | null = document.querySelector(`[aria-controls='${parentItem?.pageId}']`);
			parentElement?.focus();
		}
	}

	const addDataLayerTracking = (menuItem: MainNavigationItemModel, levelIndex: number) => {

		pushDataLayer(() => ({
			event: "GAevent",
			event_type: "click",
			event_name: "main_navigation",
			type: isLgBreakpointOrAbove ? "desktop" : "mobile",
			url: menuItem.url,
			text: menuItem.title,
			item_level: levelIndex,
			breadcrumb: activeMenuData.filter(x => x.level < levelIndex).map(x => x.menuItem.title).join(" > "),
		}));
	}

	return (
		<>
			<MotionBox
				id={parentItem?.pageId}
				color="primary.neutralGrey"
				animate={open ? "open" : "closed"}
				variants={motionVariants}
				initial="closed"
				transition={transition}
				onAnimationComplete={() => {
					if (open) {
						parentRef.current?.focus()
					}
				}}
				className={`subnav__block --${index}`}
				aria-expanded={open}
				aria-label={parentItem ? parentItem.title : "home"}
			>
				{(activeMenuData.length >= 1 && index === 0) &&
					<Box
						bg={"primary.nightGrey.600"}
						right={[null, null, isRtl ? "auto" : "98%"]}
						left={[null, null, isRtl ? "98%" : "auto"]}
						{...navBoxBgStyling(isRtl)}
					/>
				}
				{(activeMenuData.length === 4 && index === 3) &&
					<Box
						bg={"primary.nightGrey.200"}
						left={[null, null, isRtl ? "auto" : "98%"]}
						right={[null, null, isRtl ? "98%" : "auto"]}
						{...navBoxBgStyling(isRtl)}
					/>
				}
				{(activeMenuData.length === 5 && index === 4) &&
					<Box
						bg={"primary.nightGrey.100"}
						left={[null, null, isRtl ? "auto" : "98%"]}
						right={[null, null, isRtl ? "98%" : "auto"]}
						{...navBoxBgStyling(isRtl)}
					/>
				}

				<Box overflowY="auto" w={"100%"}>
					<CloseButton color="primary.aramcoBlue" _hover={{ background: "transparent", color: "primary.white" }} tabIndex={-1} className="closebutton" onClick={closeSubNav} />
					{(isSmallViewport && parentItem) &&
						<Box as="button" type="button" className="backbutton" onClick={() => navigateBack()}>
							<Icon
								variant="Next"
								w="icons.md"
								ms="0"
								me="2"
								transform={!isRtl ? "rotate(180deg)" : ""}
							/>
							{t("navigation.mainNavigation.goBack")}
						</Box>
					}
					<Flex as="ul" flexDir="column" flexWrap="nowrap" flexBasis="100%">
						{
							parentItem &&
								<Box
									as="li"
									listStyleType="none"
									pt={["2", null, null, "1.125rem"]}
									pb={["2", null, null, "1.25rem"]}
								>
									<RouterLink
										className={`subnav__item subnav__item--parent${parentItem.isActive ? " subnav__item--active" : ""}`}
										to={parentItem.url}
										ref={parentRef as never}
										onKeyDown={onKeyDown}
										onClick={() => {
											closeSubNav();
											addDataLayerTracking(parentItem, index);
										}}
									>
										<Box as="span" flex="1">
											<Box as="span" pb="2px" className="nav-text">
												{parentItem.title}
											</Box>
										</Box>
										{t("navigation.mainNavigation.seePage") && t("navigation.mainNavigation.seePage") !== "" &&
											<Box as="span" textTransform="none" fontSize="2xs" flex="1" display="flex" justifyContent="flex-end" pt={["1px", null, null, "0"]}>
												<Box as="span" className="nav-text see-page">
													{t("navigation.mainNavigation.seePage")}
												</Box>
											</Box>
										}
									</RouterLink>
								</Box>
						}

						{menuItems?.map((menuItem, menuItemIndex) => {
							if (!menuItem.menuItemChildren || menuItem.menuItemChildren.length === 0) {
								return (
									<Box as="li" key={`subnav__item_${menuItemIndex}`} listStyleType="none">
										<RouterLink
											className={`subnav__item ${menuItem.isAncestorOrSelf ? "subnav__item--active" : ""}`}
											to={menuItem.url}
											onClick={() => {
												closeSubNav();
												addDataLayerTracking(menuItem, index + 1);
											}}
										>
											<span>
												<Box as="span" className="nav-text" pb="2px">
													{menuItem.title}
												</Box>
											</span>
										</RouterLink>
									</Box>
								);
							}

							const inPath: boolean = (menuItem.isAncestorOrSelf || activeMenuData.some(item => item.menuItem === menuItem));

							return (

								<Box as="li" key={`subnav__item_${menuItemIndex}`} listStyleType="none">
									<Box
										as="button"
										className={`subnav__item ${inPath ? "subnav__item--in-path" : ""}`}
										onClick={() => openNextSubNavLevel(menuItem)}
										justifyContent="flexStart"
										aria-controls={menuItem.pageId}
									>
										<Box as="span" textAlign={isRtl ? "right" : "left"} maxWidth="calc(100% - 24px)">
											<Box as="span" className="nav-text" pb="2px">
												{menuItem.title}
											</Box>
										</Box>
										<Icon
											variant="Next"
											w="icons.md"
											ms="2"
											me="0"
											pt="1"
											transform={isRtl ? "rotate(180deg)" : ""}
										/>
									</Box>
								</Box>
							);
						})}
					</Flex>
				</Box>
			</MotionBox>
			{menuItems?.map((menuItem, menuItemIndex) => {
				const updatedIndex = index + 1;
				const matchingMenuItem = activeMenuData.some(item => item.menuItem === menuItem);

				if (menuItem.menuItemChildren.length) {
					return (
						<SubNavigationLevel
							key={menuItemIndex}
							menuItems={menuItem.menuItemChildren}
							index={updatedIndex}
							parentItem={menuItem}
							open={matchingMenuItem}
							disclosureProps={disclosureProps}
							closeSubNav={closeSubNav}
							handleLevelChange={handleLevelChange}
							activeIndex={activeIndex}
							activeMenuData={activeMenuData}
							isLgBreakpointOrAbove={isLgBreakpointOrAbove}
						/>
					);
				}
			})}
		</>
	)
}