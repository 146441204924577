import React, { FC } from "react";
import { Schema } from "~/foundation/Tracking/Schema";
import { RouteData, SitecoreContextValue } from "~/foundation/Jss";
import { ArticleHubPage, MagazineHubPage, PublicationHubPage } from "../generated-types";

type HubSchemaProps = {
	route: RouteData<ArticleHubPage & MagazineHubPage & PublicationHubPage>;
	context: SitecoreContextValue;
	resolveImageUrl: (imagePath?: string) => string | null;
}

export const HubSchema: FC<HubSchemaProps> = ({ route, context }) => {

	return (
		<Schema schema={{
			"@context": "https://schema.org",
			"@type": "collectionPage",
			"@id": `${context.custom.absoluteUrl}#collectionPage`,
			url: context.custom.absoluteUrl,
			headline: route.fields?.headline?.value,
			breadcrumb: {
				"@type": "BreadcrumbList",
				itemListElement: context.custom.breadcrumbItems.items.slice(1).map((item, index) => ({
					"@type": "ListItem",
					position: index + 1,
					item: {
						name: item.title,
						"@id": `${item.url}#WebPage`
					}
				}))
			},
			isPartOf: {
				"@type": "Website",
				name: "Aramco",
				url: context.custom.hostname,
				"@id": `${context.custom.hostname}#Website`,
				publisher: {
					"@type": "Corporation",
					"name": "Saudi Aramco",
					"url": "https://www.aramco.com",
					"@id": "https://www.aramco.com#Corporation"
				}
			}
		}} />
	);
}