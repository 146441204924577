const primary = {
	aramcoBlue: "rgb(0, 163, 224)",
	aramcoGreen: "#84BD00",
	aramcoLinkBlue: "rgb(0, 127, 173)",
	darkGreen: "#00843D",
	britishRacingGreen: "#005851",
	darkBlue: "#0033A0",
	darkGrey: "#323232",
	aramcoGrey: "#5F6369",
	brightGrey: "#F2F2F2",
	neutralGrey: "#C0C0C0",
	lightGrey: "#DADADA",
	text: "#1F1F1F",
	white: "#fff",
	black: "#000",
	nightGrey: {
		100: "#373737",
		200: "#313131",
		300: "#2D2D2D",
		400: "#2C2C2C",
		500: "#282828",
		600: "#1F1F1F",
		900: "#101010"
	}
};

const secondary = {
	teal100: "rgb(38, 168, 171)",
	teal70: "rgba(38, 168, 171, .7)",
	teal40: "rgba(38, 168, 171, .4)",
	purple100: "rgb(100, 50, 120)",
	purple70: "rgba(100, 50, 120, .7)",
	purple40: "rgba(100, 50, 120, .4)",
	yellow100: "rgb(255, 200, 70)",
	yellow70: "rgba(255, 200, 70, .7)",
	yellow40: "rgba(255, 200, 70, .4)",
	yellow00: "rgba(255, 200, 70, 0)",
	red100: "rgb(240, 95, 65)",
	red70: "rgba(240, 95, 65, .7)",
	red40: "rgba(240, 95, 65, .4)",
};

const overlay = {
	darkGreyAlpha: "rgba(50, 50, 50, .85)",
	blackAlpha: "rgba(0, 0, 0, .45)"
};

const getGradients = (rtl: boolean) => ({
	brand: `linear-gradient(71.23deg, ${primary.aramcoGreen} -12.86%, ${primary.aramcoBlue} 66.56%)`,
	progress: `linear-gradient(to left, ${primary.aramcoBlue} 0%, ${primary.aramcoBlue} 10%, ${primary.aramcoGreen} 90%, ${primary.aramcoGreen} 100%)`,
	breakingNews: `linear-gradient(${rtl ? "to right" : "to left"}, ${secondary.yellow100} 0%, ${secondary.yellow100} 10%, ${secondary.yellow00} 63%, ${secondary.yellow00} 100%)`,
	horisontalTimeline: "linear-gradient(234deg, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0.85) 80%)",
	imageQuoteMobile: "linear-gradient(23.96deg, #050606 15.34%, rgba(0, 0, 0, 0) 84.56%), rgba(0, 0, 0, 0.7)",
	imageQuoteDesktop: "linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 101.97%), rgba(0, 0, 0, 0.7)",
	footer: `linear-gradient(180deg, ${primary.darkGrey} 0%, ${primary.text} 100%)`,
	footerLight: `linear-gradient(180deg, ${elementsBackgroundColors.light} 49.51%, ${elementsBackgroundColors.footerDarkSand} 100%)`,
	footerDark: `linear-gradient(180deg, ${primary.nightGrey["600"]} 35%, ${primary.black} 100%)`,
	grey: "linear-gradient(0deg, rgba(0, 0, 0, 0) 12.18%, rgba(58, 58, 58, 0.5) 109.74%)",
	mobileNavigation: `linear-gradient(180deg, var(--chakra-colors-primary-nightGrey-400) 0%, var(--chakra-colors-primary-nightGrey-900) 100%)`,
	hygieneGlobalContacts: `linear-gradient(${rtl ? "to right" : "to left"}, rgba(255,255,255,0.1) 0%, transparent 50%)`
});

const getSiteNavigation = (rtl: boolean) => ({
	lightTheme: {
		gradient: `linear-gradient(${rtl ? "to right" : "to left"}, rgba(95, 99, 105, 0.25) 0.07%, rgba(95, 99, 105, 0.25) 10.06%, rgba(95, 99, 105, 0.00) 63.02%, rgba(95, 99, 105, 0.00) 100%)`,
		bg: "rgba(95, 99, 105, 0.25)",
		color: "primary.text",
	},
	darkTheme: {
		gradient: `linear-gradient(${rtl ? "to right" : "to left"}, rgba(50, 50, 50, .5) 0%, rgba(50, 50, 50, .5) 30%, rgba(50, 50, 50, 0) 100%)`,
		bg: "rgba(50, 50, 50, .5)",
		color: "white",
	}
});

const aramcoBreadcrumb = {
	lightTheme: {
		linkColor: `${primary.aramcoGrey}`,
		activeColor: `${primary.aramcoLinkBlue}`,
		aramcoA: "rgba(31, 31, 31, .5)",
		iconColor: "rgba(31, 31, 31, .7)",
		linkColorCurrentPage: "#313131",
	},
	darkTheme: {
		linkColor: `${primary.white}`,
		activeColor: `${primary.aramcoBlue}`,
		aramcoA: `${primary.white}`,
		iconColor: `${primary.white}`,
		linkColorCurrentPage: `${primary.white}`,
	}
}

const tags = {
	aramcoBlue: {
		500: 'var(--chakra-colors-primary-aramcoBlue)',
	},
}

const formElements = {
	aramcoLinkBlue: {
		500: 'var(--chakra-colors-primary-aramcoLinkBlue)',
	},
}

const progressBar = {
	default: "#dadada80" //lightgrey 50%
}

const font = {
	base: 'var(--chakra-colors-primary-text)',
}

const card = {
	white: "white",
	green: 'var(--chakra-colors-primary-aramcoGreen)',
	blue: 'var(--chakra-colors-primary-aramcoBlue)',
};

const ribbon = {
	bg: 'transparent'
}

const themeBackgroundColors = {
	aramcoBlue: "var(--chakra-colors-primary-aramcoBlue)",
	aramcoGrey: "var(--chakra-colors-primary-aramcoGrey)",
	brightGrey: "var(--chakra-colors-primary-brightGrey)",
	darkGrey: "var(--chakra-colors-primary-darkGrey)",
	darkGreyGradient: "linear-gradient(180deg, var(--chakra-colors-primary-darkGrey) 0%, var(--chakra-colors-primary-text) 100%)",
	coldGreen: "#E0E7E6",
	britishRacingGreen: "var(--chakra-colors-primary-britishRacingGreen)",
	darkGreen: "var(--chakra-colors-primary-darkGreen)",
	darkBlue: "var(--chakra-colors-primary-darkBlue)"
}

const elementsBackgroundColors = {
	light: "#FFF1D1",
	dark: "#1F1F1F",
	footerDarkSand: "#FFDB87"
}

const navigationColors = {
	lightBg: "rgba(255, 255, 255, .95)",
	lightBgTransparent: "rgba(255, 255, 255, 0)",
	darkBg: "rgba(50, 50, 50, .95)",
	darkBgTransparent: "rgba(50, 50, 50, 0)"
}

export const getColors = (rtl: boolean) => ({
	primary,
	secondary,
	overlay,
	gradients: getGradients(rtl),
	siteNavigation: getSiteNavigation(rtl),
	tags,
	formElements,
	aramcoBreadcrumb,
	progressBar,
	font,
	card,
	ribbon,
	themeBackgroundColors,
	elementsBackgroundColors,
	navigationColors
});

type ColorsObj = ReturnType<typeof getColors>;
export type Colors = (ColorsObj[keyof ColorsObj]) | "currentColor";