import "@total-typescript/ts-reset";
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom";
import AppRoot from "~/project/AramcoCom/AppRoot";
import config from "~/temp/config";
import i18ninit from "~/foundation/Dictionary/i18n";
import { HelmetProvider } from "react-helmet-async";
// import reportWebVitals from "./reportWebVitals"
// import * as serviceWorker from "./serviceWorker"
import { preloadReady } from "~/foundation/ReactLoadable";
import reportWebVitals from "./reportWebVitals";
import { SitecoreConfigProvider } from "./foundation/Jss/SitecoreConfigContext";

let renderFunction = (rootElement: HTMLElement, component: JSX.Element) => {
	const root = ReactDOM.createRoot(rootElement);
	root.render(component);
};

let initLanguage = config.defaultLanguage;

/*
	SSR Data
	If we're running in a server-side rendering scenario,
	the server will provide JSON in the #__JSS_STATE__ element
	for us to acquire the initial state to run with on the client.

	This enables us to skip a network request to load up the layout data.
	We are emitting a quiescent script with JSON so that we can take advantage
	of JSON.parse()'s speed advantage over parsing full JS, and enable
	working without needing `unsafe-inline` in Content Security Policies.

	SSR is initiated from /server/server.js.
*/
let __JSS_STATE__: any = null; // eslint-disable-line
const ssrRawJson = document.getElementById("__JSS_STATE__");
if (ssrRawJson) {
	__JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
	// when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
	renderFunction = ReactDOM.hydrateRoot;

	// set i18n language SSR state language instead of static config default language
	initLanguage = __JSS_STATE__.sitecore.context.language;
}


// used by GTM
window.getTrackingData = (element: HTMLElement, index: number) => {
	const dataTracking = element.dataset?.trackingData;
	if (!dataTracking) {
		return;
	}

	const json = JSON.parse(dataTracking) as any; // eslint-disable-line
	const keys = Object.keys(json);
	const name = keys[index];
	const value = json[name];

	return {
		name,
		value
	};
}

const rootElement = document.getElementById("root");
if (!rootElement) {
	throw "Could not find root element";
}

const siteName = rootElement.dataset.siteName || config.sitecoreSiteName;


/*
	App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.

i18ninit(siteName, initLanguage, undefined)
	.then(() => preloadReady())
	.then(() => {
		if (process.env.NODE_ENV === "development") {
			return import(/* webpackChunkName: "LOCALHOST" */ "./localhost");
		}
	})
	.then(() => {

		renderFunction(
			rootElement,
			// StrictMode will make the page render twice at start, but only in dev environment
			<React.StrictMode>
				<SitecoreConfigProvider sitecoreSiteName={siteName}>
					<HelmetProvider>
						<AppRoot
							path={window.location.pathname}
							Router={BrowserRouter}
							ssrState={__JSS_STATE__}
							siteName={siteName}
							routerContext={{}}
						/>
					</HelmetProvider>
				</SitecoreConfigProvider>
			</React.StrictMode>
		);
	});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// https://www.youtube.com/watch?v=V0lL1753VHo 12:00
