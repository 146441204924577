import { ComponentStyleConfig } from "@chakra-ui/react";

export const text = (rtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontWeight: "400",
		lineHeight: "base",
	},
	sizes: {
		xxl: {
			//h1
			fontFamily: "heading",
			fontSize: ["4xl", null, null, "6xl"],
			fontWeight: "300",
			lineHeight: "1",
			letterSpacing: rtl ? "0" : [".01em", null, null, ".02em"],
		},
		xl: {
			//h2
			fontFamily: "heading",
			fontSize: ["3xl", null, null, "5xl"],
			fontWeight: "300",
			lineHeight: ["1.05", null, null, "1"],
			letterSpacing: ["widest", null, null, "wider"],
		},
		lg: {
			//h3
			fontFamily: "heading",
			fontWeight: "300",
			fontSize: ["2xl", null, null, "4xl"],
			lineHeight: ["1.16", null, null, "1"],
			letterSpacing: ["normal", null, null, "wide"],
		},
		md: {
			//h4
			fontFamily: "body",
			fontWeight: "400",
			fontSize: ["xl", null, null, "3xl"],
			lineHeight: ["1.29", null, null, "1.2"],
			letterSpacing: ["normal", null, null, "wide"],
		},
		sm: {
			//h5
			fontFamily: "body",
			fontWeight: "400",
			fontSize: ["xl", null, null, "2xl"],
			lineHeight: ["base", null, null, "1.354"],
			letterSpacing: ["normal", null, null, "wide"],
		},
		xs: {
			//h6
			fontFamily: "body",
			fontWeight: "400",
			fontSize: ["md", null, null, "lg"],
			lineHeight: ["1.4", null, null, "1.333"],
			letterSpacing: ["wider"],
		},
		xxs: {
			fontFamily: "body",
			fontWeight: "400",
			fontSize: ["2xs"],
			lineHeight: ["base"],
			letterSpacing: ["wider"],
		},
		body: {
			fontFamily: "body", //Ghawar
			fontWeight: "300", //Light
			fontSize: ["sm", null, null, "body"],
			lineHeight: "7",
			letterSpacing: ["wide"],
		},
	},
	variants: {
		overline: {
			fontFamily: "overline",
			fontWeight: "400",
			fontSize: ["2xs", null, null, "xs"],
			lineHeight: ["1rem", null, null, "1.125rem"],
			textTransform: rtl ? "none" : "uppercase",
			letterSpacing: rtl ? "0" : [".08em", null, null, ".09em"],
		},
		breadcrumb: {
			fontFamily: "body",
			fontSize: "sm", //0.875rem // 14px
			fontWeight: "400",
			lineHeight: "1.375rem",
			letterSpacing: "wider",
			color: "primary.aramcoGrey",
		},
		caption: {
			fontFamily: "body",
			fontSize: ["xs", null, null, "sm"],
			fontWeight: "400",
			lineHeight: "7",
			letterSpacing: ["wider", null, null, "wide"],
			color: "primary.text",
		},
		byline: {
			fontFamily: "body",
			fontWeight: "400",
			fontSize: "sm",
			letterSpacing: rtl ? "0" : ".01em",
			lineHeight: "1.25",
			textAlign: "center",
		},
		quote: {
			fontSize: "lg",
			lineHeight: ["1.3333", null, "1.6667"],
			letterSpacing: rtl ? "0" : ".01em",
			textAlign: "center",
		},
		"intro-text": {
			fontFamily: "body",
			fontWeight: "400",
			fontSize: ["body", null, null, "lg"],
			lineHeight: ["7", null, null, "8"],
		},
		footerHeading: {
			fontFamily: "heading",
			fontWeight: "600",
			fontSize: "sm",
			lineHeight: "9",
			letterSpacing: "superWide",
			textTransform: rtl ? "none" : "uppercase",
		},
		footerBody: {
			fontFamily: "body",
			fontWeight: "400",
			fontSize: "xs",
			lineHeight: "1.125rem",
			letterSpacing: "wider",
		},
		//OBS mediaSpec is static - no conditional rtl styling
		mediaSpec: {
			fontFamily: "Ghawar",
			fontSize: ".75rem",
			fontWeight: "400",
			lineHeight: "1.5rem",
			letterSpacing: ".02em",
		},
		keyIrNumber: {
			fontFamily: "heading",
			fontWeight: "600",
			fontSize: [
				"4xl",
				null,
				"xl",
				rtl ? null : "2xl",
				rtl ? "2xl" : "4xl",
				rtl ? "4xl" : "5xl",
			],
			lineHeight: rtl ? "150%" : "normal",
			letterSpacing: rtl ? "0" : ".02em",
		},
		operationsTagline: {
			color: "white",
			fontFamily: "Ghawar",
			fontWeight: "900",
			fontSize: "14px",
			lineHeight: "18px",
			letterSpacing: "9%",
		},
		operationsHeader: {
			color: "rgba(222, 230, 229, 1)",
			fontFamily: "ManifaPro2",
			fontWeight: "200",
			fontSize: "69px",
			lineHeight: "69px",
			letterSpacing: "2%",
		},
		operationsLegend: {
			color: "rgba(218, 218, 218, 1)",
			fontFamily: "Ghawar",
			fontWeight: "400",
			fontSize: "14px",
			lineHeight: "32px",
			letterSpacing: "2%",
		},
		default: {
			//color: 'font.base',
		},
	},
	// The default size and variant values
	defaultProps: {
		//variant: 'default',
	},
});
