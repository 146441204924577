import React from "react";
import { PageThemeBase, ThemeRenderingParameters } from "../generated-types";
import { useSitecoreContext } from "~/foundation/Jss";
import { type ColorThemeId } from "..";
import { ColorThemeProvider } from "../ColorThemeContext";
import { getColorTheme } from "./themable";

type ThemableProps = {
	params?: ThemeRenderingParameters;
	rendering: {
		componentName: string;
	}
}


type WithThemeProps = {
	fallbackToPageTheme?: boolean;
}

// https://stackoverflow.com/questions/40510611/typescript-interface-require-one-of-two-properties-to-exist


export function withNavigationTheme({ fallbackToPageTheme = false }: WithThemeProps) {

	return function WithThemeInputComponent<P extends object>(InputComponent: React.ComponentType<P>) {

		const WithNavigationTheme = (props: P & ThemableProps) => {
			const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
			const isRtl = sitecoreContext?.custom.settings.isRtl || false;
			const navigationColorTheme = getColorTheme(sitecoreContext.route?.fields?.navigationColorTheme?.value as ColorThemeId, isRtl);

			if (!navigationColorTheme && fallbackToPageTheme) {
				const pageTheme = getColorTheme(sitecoreContext.route?.fields?.colorTheme?.value as ColorThemeId, isRtl);
				if (pageTheme) {
					return (
						<ColorThemeProvider colorTheme={pageTheme} componentName={props.rendering.componentName}>
							<InputComponent {...props} />
						</ColorThemeProvider>
					);
				}
			} else if (navigationColorTheme) {
				return (
					<ColorThemeProvider colorTheme={navigationColorTheme} componentName={props.rendering.componentName}>
						<InputComponent {...props} />
					</ColorThemeProvider>
				);
			}

			return <InputComponent {...props} />;
		}

		WithNavigationTheme.displayName = "WithNavigationTheme";

		return WithNavigationTheme;
	}
}