/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Placeholder, type PlaceholderComponentProps } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type ComponentRendering } from "@sitecore-jss/sitecore-jss/layout";

type PlaceholderWrapperProps = PlaceholderComponentProps & {
	customReactKey?: (placeholderData: ComponentRendering[], rendering: ComponentRendering, index: number) => string;
	appendKey?: string;
}

const PlaceholderWrapper: FC<PlaceholderWrapperProps> = (props): any => {

	// this is for storybook
	if (process.env.STORYBOOK) {

		// eslint-disable-next-line
    const elements = ((props.rendering as any).ph?.[props.name] as unknown as JSX.Element[])?.filter(Boolean) || [];

		if (typeof props.renderEmpty === "function" && elements.length === 0) {
			return props.renderEmpty(elements);
		}

		if (typeof props.render === "function") {
			return props.render(elements, [], props);
		}

		if (typeof props.renderEach === "function") {
			return elements.map(props.renderEach);
		}

		return elements;
	}

	let customReactKey = props.customReactKey;
	if (!customReactKey) {
		customReactKey = (placeholderData, rendering, index) => {
			const key = rendering.uid
				? `${rendering.uid}-${index}`
				: `component-${index}`;

			return key;
		}
	}

	return <Placeholder {...props} customReactKey={customReactKey} />;
};

export default PlaceholderWrapper;