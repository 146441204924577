import {
	Box, BoxProps,
	Button, Flex, Link,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger, Stack, Text
} from "@chakra-ui/react";
import React, { FC } from "react";

import { Icon } from "../Icon";
import { useSitecoreContext } from "~/foundation/Jss";

type GlobalContactsProps = BoxProps;

export const GlobalContacts: FC = (props: GlobalContactsProps) => {

	const { sitecoreContext } = useSitecoreContext();
	const navElements = sitecoreContext?.custom?.settings?.siteNavigation?.sitesNavigationElements;
	const current = sitecoreContext?.custom?.settings?.siteNavigation?.siteTitleText;

	if (!navElements?.length) {
		return null;
	}

	return (
		<Box {...props} >
			<Popover>
				<PopoverTrigger>
					<Button size="md" display="flex" gap={3}>
						<Text fontSize="xs" display={{ base: "none", lg: "block" }}>{current}</Text>
						<Flex gap={1}>
							<Icon variant="Globe" w={{ base: 6, lg: 5 }} />
							<Icon variant="Down" />
						</Flex>
					</Button>
				</PopoverTrigger>
				<PopoverContent p={2} minW={{ base: "84dvw", sm: "auto" }} mx={{ base: "8dvw", sm: "auto" }} maxW="84dvw">
					<PopoverBody overflow="auto" maxH="90dvh" >
						<Stack alignItems="stretch">
							{
								navElements?.map((site, si) => (
									<Flex key={si} justifyContent="space-between">
										<Text fontSize="xs">{site.siteTitle}</Text>
										<Flex gap={2} ms={5}>
											{
												site.siteLanguages?.map((language, li) => (
													<React.Fragment key={li}>
														{li > 0 && (<Text lineHeight={1}> | </Text>)}
														<Link
															color="primary.aramcoLinkBlue"
															textDecoration="underline"
															fontSize="xs"
															key={li}
															href={`${site.siteUrl}/${language.languageLink}`}
															target="_blank">
															{language.languageTitle}
														</Link>
													</React.Fragment>
												))
											}
										</Flex>
									</Flex>
								))
							}
						</Stack>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
}