import React, { FC } from "react";
import { BreadcrumbNavigationProps } from "../generated-types";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Breadcrumb } from "@chakra-ui/react";
import { BreadCrumbStyling } from "./styles";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { useSitecoreContext } from "~/foundation/Jss";
import { PageCenteredBase, PageThemeBase } from "~/foundation/Theme/generated-types";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";

export const critical = true;

export const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = () => {
	const { sitecoreContext } = useSitecoreContext<PageThemeBase & PageCenteredBase>();
	const breadcrumbItems = sitecoreContext.custom.breadcrumbItems?.items;

	const theme = usePageTheme(sitecoreContext);
	const centeredHeader = sitecoreContext.route?.fields?.showPageHeaderCentered?.value || false;

	if (!breadcrumbItems?.length) {
		return <></>;
	}

	return (
		<>
			{/* <BreadcrumbSchema itemListElement={breadcrumbItems
				.filter(x => x.url && x.title)
				.map(x => ({
					name: x.title!,
					item: x.url!,
				}))
			}/> */}
			<Ribbon bgColor="ribbon.bg" {...BreadCrumbStyling(theme,centeredHeader)}>
				<ContentWrapper py="2">
					<Breadcrumb
						spacing="2"
						separator={""}
						className="breadcrumb"
						zIndex={1}
					>
						{breadcrumbItems
							.filter(x => x.title)
							.map((breadcrumbItem, index) => (
								<BreadcrumbItem
									key={`breadcrumb_item_${index}`}
									isCurrentPage={breadcrumbItems.length - 1 === index}
									menuItems={breadcrumbItem.children}
									showChildren={breadcrumbItem.showChildren}
									url={breadcrumbItem.url}
									title={breadcrumbItem.title!}
									index={index}
									prevItems={breadcrumbItems.filter((x, i) => i <= index && i > 0)}
								/>
							))}
					</Breadcrumb>
				</ContentWrapper>
			</Ribbon>
		</>
	)
};