import { getDefaultColorTheme } from "./color-themes/default-color-theme";
import { getBrightGreyColorTheme } from "./color-themes/bright-grey-theme";
import { getColdGreenColorTheme } from "./color-themes/cold-green-theme";
import { getDarkGreenColorTheme } from "./color-themes/dark-green-theme";
import { getAramcoBlueColorTheme } from "./color-themes/aramco-blue-theme";
import { getAramcoGreyColorTheme } from "./color-themes/aramco-grey-theme";
import { getDarkGreyColorTheme } from "./color-themes/dark-grey-theme";
import { getDarkGreyGradientColorTheme } from "./color-themes/dark-grey-gradient-theme";
import { getDarkBlueColorTheme } from "./color-themes/dark-blue-theme";
import { getDarkColorTheme } from "./color-themes/dark-theme";
import { getLightColorTheme } from "./color-themes/light-theme";
import { getBritishRacingGreenColorTheme } from "./color-themes/british-racing-green-theme";

export const themes = {
	"1": getDefaultColorTheme,
	"2": getBrightGreyColorTheme,
	"3": getDarkGreyColorTheme,
	"4": getDarkGreyGradientColorTheme,
	"5": getColdGreenColorTheme,
	"6": getDarkGreenColorTheme,
	"7": getAramcoBlueColorTheme,
	"8": getAramcoGreyColorTheme,
	"9": getDarkBlueColorTheme,
	"10": getDarkColorTheme,
	"11": getLightColorTheme,
	"12": getBritishRacingGreenColorTheme,
};

export type ColorThemeId = keyof typeof themes;