import { getFileNameFromUrl, getFullUrl } from "~/foundation/Utils";
import type { OutputType } from "jszip";

/**
 * Make add files from urls to a zip file and start downloading it
 * @param urls cannot include hostname duo to CORS
 * @returns base64 of generated zip files
 */
export const generateZipWithFilesFromUrls = async <T extends OutputType>(outputType: T, urls: string[]) => {
	try {
		const JSZip = await import(/* webpackChunkName: "jszip" */ "jszip").then(x => x.default);
		const zip = new JSZip();

		const requests = urls.map(url => {
			return new Promise<Response>((resolve, reject) => {
				fetch(getFullUrl(url)).then(resolve).catch(reject);
			});
		});

		const responses = await Promise.all(requests);

		for (const response of responses) {
			const blob = await response.blob();
			const filename = getFileNameFromUrl(response.url);

			zip.file(filename, blob);
		}

		return await zip.generateAsync({ type: outputType });
	} catch (e) {
		console.error(e);
	}

	return null;
};

export const saveAs = async (blob: Blob, fileName?: string) => {
	let isFileSaverSupported = false;
	try {
		isFileSaverSupported = !!new Blob;
	} catch (e) { } // eslint-disable-line

	if (!isFileSaverSupported) {
		console.error("'file-saver' is not supported");
		return;
	}

	const fileSaver = await import(/* webpackChunkName: "fileSaver" */ "file-saver").then(x => x.default);
	fileSaver.saveAs(blob, fileName);
}