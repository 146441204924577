/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type FC, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";

export type Tag = {
	id: string;
	title: string;
};

type SlideInTagSearchContextValue = {
	onOpen: () => void;
	isOpen: boolean;
	onClose: () => void;
	setCurrentTag: React.Dispatch<React.SetStateAction<Tag | null>>;
	currentTag: Tag | null;
	getDisclosureProps: (props?: any) => any;
	getButtonProps: (props?: any) => any;
}

// eslint-disable-next-line
// @ts-ignore
export const SlideInTagSearchContext = React.createContext<SlideInTagSearchContextValue>({ });

type SlideInTagSearchProviderProps = {
	children: React.ReactNode;
}

export const SlideInTagSearchProvider: FC<SlideInTagSearchProviderProps> = ({ children }) => {
	const { onOpen, isOpen, onClose, getDisclosureProps, getButtonProps } = useDisclosure();
	const [currentTag, setCurrentTag] = useState<Tag | null>(null);

	return (
		<SlideInTagSearchContext.Provider value={{ onOpen, isOpen, onClose, currentTag, setCurrentTag, getDisclosureProps, getButtonProps }}>
			{children}
		</SlideInTagSearchContext.Provider>
	);
}