import React, { PropsWithChildren, createContext, useEffect, useReducer } from 'react';
import { SuitcaseItem, getSuitcase, updateSuitcase } from '.';
import { SuitcaseAction, suitcaseReducer } from './suitcaseReducer';

// We use any here because we don't have an initial value, but we know that it will always have
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SuitcaseContext = createContext<[SuitcaseItem[], React.Dispatch<SuitcaseAction>]>([] as any);

export const SuitcaseStore = ({ children }: PropsWithChildren) => {
	const [suitcase, dispatch] = useReducer(suitcaseReducer, []);

	useEffect(() => {
		// We cannot use initialState param because of hydration after SSR will fail.
		const initState = getSuitcase();
		dispatch({ type: "INITIALIZE", payload: initState });
	}, []);

	useEffect(() => {
		updateSuitcase(suitcase);
	}, [suitcase]);

	return (
		<SuitcaseContext.Provider value={[suitcase, dispatch]}>
			{children}
		</SuitcaseContext.Provider>
	);
};