import React, { useContext, type FC } from "react";
import {
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Button as ChakraButton,
	Portal,
	Text as ChakraText,
	Box,
	PopoverFooter,
	Button,
} from "@chakra-ui/react";
import { Icon } from "../Icon";
import { type ColorTheme } from "~/foundation/Theme";
import { type WordModel } from "~/foundation/SitecoreContent/generated-types";
import { SlideInTagSearchContext } from "~/foundation/Search/SlideInTagSearchContext";
import { useSitecoreContext } from "~/foundation/Jss";

type WordExplainerProps = {
	isRtl: boolean;
	theme: ColorTheme;
	text: string;
	wordExplainer: WordModel;
}

export const WordExplainer: FC<WordExplainerProps> = ({ text, isRtl, theme, wordExplainer }) => {
	const { sitecoreContext } = useSitecoreContext();
	const { setCurrentTag, getButtonProps } = useContext(SlideInTagSearchContext);
	const { colors } = theme;
	const nearestHub = sitecoreContext.custom.nearestHub;

	return (
		<Popover
			isLazy
			direction={isRtl ? "rtl" : "ltr"}
			placement="top"
			arrowShadowColor="0px 12px 24px rgba(0, 0, 0, 0.16)">
			<PopoverTrigger>
				<ChakraButton
					variant="popover"
					bg={colors.wordExplainer.bg}
					color={colors.wordExplainer.color}
					px=".625rem"
					_hover={{
						color: colors.wordExplainer.activeColor,
					}}>
					{text}
				</ChakraButton>
			</PopoverTrigger>
			<Portal>
				<PopoverContent
					py="4"
					px="6"
					bg={colors.wordExplainer.bg}
					color={colors.wordExplainer.color}
					borderRadius="sm"
					boxShadow="0px 12px 24px rgba(0, 0, 0, 0.16)"
					border="none">
					<PopoverArrow bg={colors.wordExplainer.bg} />
					<PopoverCloseButton top="1" right={isRtl ? "auto" : "1"} left={isRtl ? "1" : "auto"}>
						<Box>
							<Icon variant="Close" w="icons.md" />
						</Box>
					</PopoverCloseButton>
					<PopoverHeader p="0">
						<ChakraText size="body" mb="2">{wordExplainer.title}</ChakraText>
					</PopoverHeader>
					<PopoverBody p="0">
						<ChakraText variant="footerBody" mb="2">{wordExplainer.description}</ChakraText>
					</PopoverBody>
					{(nearestHub && wordExplainer.tags.length > 0) && (
						<PopoverFooter p="0">
							<Box>
								{wordExplainer.tags.map(tag => (
									<Button
										variant="outline-tag"
										color={colors.wordExplainer.color}
										borderColor={colors.tags.color}
										key={tag.id}
										{...getButtonProps()}
										onClick={() => setCurrentTag({ id: tag.id, title: tag.name })}
										me={[".5rem", null, null, "1rem"]}
										mt={[".5rem", null, null, "1rem"]}
										mb="0"
										_hover={{
											color: colors.wordExplainer.activeColor,
										}}
									>
										{tag.name}
									</Button>
								))}
							</Box>
						</PopoverFooter>
					)}
				</PopoverContent>
			</Portal>
		</Popover>
	);
}