import React, { FC, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

type ProgressBarProps = {
	isRtl: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = ({ isRtl }) => {
	const [scroll, setScroll] = useState(0);
	const ticking = useRef(false);
	const footerHeightRef = useRef(0);

	useEffect(() => {
		const footerHeight = (document.querySelector(".page-footer") as HTMLElement)?.clientHeight || 0;
		footerHeightRef.current = footerHeight;

		document.addEventListener("scroll", updateProgressBar)
		updateProgressBar()
		return () => document.removeEventListener("scroll", updateProgressBar)
	}, []);

	const updateProgressBar = () => {
		if (!ticking.current) {
			const windowHeight = document.documentElement.offsetHeight - window.innerHeight - footerHeightRef.current;

			window.requestAnimationFrame(() => {
				setScroll(Math.floor((window.pageYOffset / windowHeight) * 100));
				ticking.current = false;
			});
		}

		ticking.current = true;
	}

	const height = "1";

	return (
		<Box
			position="fixed"
			top="0"
			left={isRtl ? "auto" : "0"}
			right={isRtl ? "0" : "auto"}
			height={height}
			width={`${scroll}%`}
			zIndex="999"
			transitionDuration=".5s"
			transitionProperty="width"
			transitionTimingFunction="aramcoCubic"
			bg="gradients.progress"
		/>
	);
}