/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

type Address = {
	"@type": string;
	streetAddress?: string;
	postalCode?: string;
	addressLocality?: string;
	addressCountry?: string;
	addressRegion?: string;
}

type Department = {
	"@type": string;
	"@id": string;
	name?: string;
	url: string;
	address?: Address;
}

type Publisher = {
	"@type": string;
	"@id": string;
	name?: string;
	alternateName?: string;
	description?: string;
	url?: string;
	logo?: {
		"@type": "ImageObject",
		url: string | null;
	};
	address?: Address;
	department?: Department[];
	sameAs?: string[];
};

type DefinedTerm = {
	"@type": "DefinedTerm";
	"@id": string;
	name: string;
	url: string;
}

export type SchemaType = {
	"@context": "https://schema.org";
	"@type": string;
	"@id"?: string;
	headline?: string;
	url?: string;
	name?: string;
	description?: string;
	thumbnailUrl?: string;
	uploadDate?: string;
	duration?: string;
	contentUrl?: string;
	embedUrl?: string;
	image?: string | null;
	breadcrumb?: {
		"@type": "BreadcrumbList";
		itemListElement: {
			"@type": "ListItem";
			position: number;
			item: {
				"@id": string;
				name: string;
			}
		}[];
	},
	mainEntity?: {
		"@type": string;
		"@id": string;
		name?: string;
		url?: string;
		headline?: string;
		articleBody?: string;
		image?: string[];
		datePublished?: string;
		about?: DefinedTerm[];
		author?: {
			"@type": "Person";
			name: string;
		}
		potentialAction?: {
			"@type": string;
			target: string;
			[key: string]: string
		};
		publisher?: Publisher[] | Publisher;
	};
	mentions?: [];
	publisher?: Publisher[] | Publisher;
	isPartOf?: {
		"@type": string;
		"@id": string;
		name?: string;
		url?: string;
		potentialAction?: {
			"@type": string;
			target: string;
			[key: string]: string
		};
		publisher?: Publisher[] | Publisher;
	}
}

type SchemaProps = {
	schema: SchemaType;
}

export const Schema: FC<SchemaProps> = ({ schema }) => {
	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(schema)}
			</script>
		</Helmet>
	)
}
