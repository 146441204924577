import React, { FC, useEffect, useState } from "react";
import {
	Box,
	Button,
	Flex,
	Text as ChakraText,
	Modal,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	useOutsideClick
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Icon } from "~/foundation/Components/Icon";
import { useSitecoreContext } from "~/foundation/Jss";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { getRestrictedAccessCookie, setCookie } from "~/foundation/Utils/cookie";
import { CountryModel } from "~/foundation/SitecoreContent/generated-types";
import { RestrictedAccessStyling } from "./styles";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { AnimatePresence } from "framer-motion";


export const RestrictedAccess: FC = () => {
	const { sitecoreContext } = useSitecoreContext();
	const disclaimerInfo = sitecoreContext.custom.settings.disclaimerInfo;
	const acceptedCountries = disclaimerInfo.countries?.filter(
		(country) =>
			!disclaimerInfo.restrictedCountries?.map((restrictCountry) =>
				JSON.stringify(restrictCountry)).includes(JSON.stringify(country)
			)
	);
	const {
		onClose: onCloseSelect,
		getDisclosureProps: getDisclosurePropsSelect,
		getButtonProps: getButtonPropsSelect,
	} = useDisclosure();
	const buttonProps = getButtonPropsSelect();
	const disclosureProps = getDisclosurePropsSelect();

	const navigate = useNavigate();
	const [t] = useTranslation<GlobalDictionary>();


	const [isModalOpen, setIsModalOpen] = useState(false);


	const selectRef = React.useRef<HTMLElement | null>(null);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [selectedCountry, setSelectedCountry] = useState<CountryModel>();

	useEffect(() => {
		const restictedAccessCookie = getRestrictedAccessCookie();

		setIsModalOpen(restictedAccessCookie === "" || acceptedCountries && !acceptedCountries.find(country => country.countryCode === restictedAccessCookie) || false);
	}, []);

	useOutsideClick({
		ref: selectRef,
		handler: () => onCloseSelect(),
	});

	const onCloseCustomSelect = (country: CountryModel) => {
		onCloseSelect();

		setSelectedCountry(country);
	}

	const onCloseModal = () => {
		setIsModalOpen(false);
	}

	const motionVariants = {
		exit: {
			opacity: 0
		},
		active: {
			opacity: 1
		}
	}

	const transition = {
		default: {
			ease: [.35, 1, .45, 1],
			duration: .125
		}
	}

	return (
		<Modal
			isCentered
			isOpen={isModalOpen}
			onClose={onCloseModal}
			motionPreset="slideInBottom"
			initialFocusRef={inputRef}
			scrollBehavior="inside"
		>
			<ModalOverlay bg="overlay.darkGreyAlpha" />
			<ModalContent zIndex="10" top={["2rem", null, null, "var(--chakra-sizes-headerHeightLG)"]} bottom={["2rem", null, null, "var(--chakra-sizes-headerHeightLG)"]}>
				<ContentWrapper
					bgColor="primary.white"
					w="100%"
					maxW={[null, null, "60rem"]}
					px={["var(--chakra-sizes-pageMarginSM)", null, "16"]}
					{...RestrictedAccessStyling()}
				>
					<AnimatePresence mode="wait" initial={false}>
						{!selectedCountry &&
							<MotionBox
								variants={motionVariants}
								transition={transition}
								initial={motionVariants.exit}
								exit={motionVariants.exit}
								animate={motionVariants.active}
								key="step1"
								opacity="0"
							>
								<ChakraText size="xl" mb={["1.775rem"]}>
									{disclaimerInfo.step1Title}
								</ChakraText>
								<ChakraText size="body" mb={["1.775rem"]}>
									{disclaimerInfo.step1Text}
								</ChakraText>
								<Flex ref={selectRef as never} order={[2, null, null, 0]} alignItems="center">
									<Box position="relative">
										<Button
											className="restricted-access__select-button"
											{...buttonProps}
										>
											{t("general.restrictedAccess.selectCountry")}
											<Icon variant="Down" ms=".5rem" />
										</Button>

										<Flex
											{...disclosureProps}
											flexDirection="column"
											position="absolute"
											top={["70%", null, null, "110%"]}
											left="insetStart"
											width="max-content"
											minW="100%"
											bg="primary.aramcoLinkBlue"
											borderRadius="md"
											p="1"
											zIndex="2"
										>
											{disclaimerInfo.countries?.map(countryItem => {
												return (
													<Box
														key={countryItem.countryCode}
														as="button"
														type="button"
														className="restricted-access__option"
														onClick={() => {
															countryItem.countryName && onCloseCustomSelect(countryItem);
														}}
													>
														{countryItem.countryName}
													</Box>
												);
											})}
										</Flex>
									</Box>
								</Flex>
							</MotionBox>
						}
						{(selectedCountry && !acceptedCountries?.find(country => country.countryCode === selectedCountry?.countryCode)) &&
							<MotionBox
								variants={motionVariants}
								transition={transition}
								initial={motionVariants.exit}
								exit={motionVariants.exit}
								animate={motionVariants.active}
								key="step3"
								opacity="0"
							>
								<ChakraText size="xl" mb={["1.775rem"]}>
									{disclaimerInfo.step3Title}
								</ChakraText>
								<ChakraText size="body" mb={["1.775rem"]}>
									{disclaimerInfo.step3Text}
								</ChakraText>
								<Button
									className="restricted-access__button"
									onClick={() => {
										navigate(sitecoreContext.custom.settings.disclaimerInfo.abortLink?.url || "/");
									}}>
									{t("general.restrictedAccess.backToHome")}
								</Button>
							</MotionBox>
						}
						{acceptedCountries?.find(country => country.countryCode === selectedCountry?.countryCode) &&
							<MotionBox
								variants={motionVariants}
								transition={transition}
								initial={motionVariants.exit}
								exit={motionVariants.exit}
								animate={motionVariants.active}
								key="step2"
								opacity="0"
							>
								<ChakraText size="xl" mb={["1.775rem"]}>
									{disclaimerInfo.step2Title}
								</ChakraText>
								<ChakraText size="body" mb={["1.775rem"]} maxH="12rem" overflowY="auto" dangerouslySetInnerHTML={{ __html: `${disclaimerInfo.step2Text}` }} />
								<Button
									className="restricted-access__button"
									mb={["1rem", null, null, 0]}
									me="1rem"
									onClick={() => {
										if (selectedCountry?.countryCode) {
											setCookie("aramco_restricted_access_allow", selectedCountry.countryCode, 7);
										}

										onCloseModal();
									}}
								>
									{t("general.labels.agree")}
								</Button>
								<Button
									className="restricted-access__button"
									onClick={() => {
										navigate(sitecoreContext.custom.settings.disclaimerInfo.abortLink?.url || "/");
									}}>
									{t("general.labels.reject")}
								</Button>
							</MotionBox>
						}
					</AnimatePresence>
				</ContentWrapper>
			</ModalContent>
		</Modal>
	)
}