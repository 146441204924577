import { SuitcaseItem } from '.';
import { pushDataLayer } from '~/foundation/Tracking/GoogleTagManager';

type SuitcaseActionWithoutInitialValue = {
	type: "INITIALIZE";
	payload: SuitcaseItem[];
}

type SuitcaseActionWithoutPayload = {
	type: "CLEAR";
}

type SuitcaseActionWithPayloadAdd = {
	type: "ADD";
	payload: SuitcaseItem;
}

type SuitcaseActionWithPayloadRemove = {
	type: "REMOVE";
	payload: string;
}


export type SuitcaseAction = SuitcaseActionWithoutInitialValue | SuitcaseActionWithPayloadAdd | SuitcaseActionWithPayloadRemove | SuitcaseActionWithoutPayload;

export function suitcaseReducer(state: SuitcaseItem[], action: SuitcaseAction) {
	switch (action.type) {
		case "INITIALIZE": {
			return action.payload;
		}
		case "ADD": {
			if (!state.some(x => x.url === action.payload.url)) {
				state = [...state, action.payload];

				pushDataLayer(() => ({
					event: "GAevent",
					event_type: "click",
					event_name: "multi_file_suitcase",
					type: "add to suitcase",
					url: action.payload.url,
					text: action.payload.name,
					file_type: action.payload.type
				}));
			}
			return state;
		}
		case "REMOVE": {
			const found = state.find(x => x.url === action.payload);

			if (found) {
				pushDataLayer(() => ({
					event: "GAevent",
					event_type: "click",
					event_name: "multi_file_suitcase",
					type: "remove from suitcase",
					url: found.url,
					text: found.name,
					file_type: found.type
				}));

				return state.filter(x => x.url !== action.payload);
			}

			return state;
		}
		case "CLEAR": {
			pushDataLayer(() => ({
				event: "GAevent",
				event_type: "click",
				event_name: "multi_file_suitcase",
				type: "clear suitcase",
			}));

			return [];
		}
		default: {
			return state;
		}
	}
}
