/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { type RichTextProps as SitecoreRichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { type RichTextField, useSitecoreContext } from "..";
import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';

type TableRichTextProps = SitecoreRichTextProps & {
	field?: RichTextField
};

const TableRichTextWrapper: FC<TableRichTextProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();
	const [richTextComponent, setRichTextComponent] = useState<React.JSX.Element>(<RichText {...props} />)

	if (sitecoreContext.pageEditing) {
		return <RichText {...props} />;
	}

	if (!props?.field?.value) {
		return <></>;
	}

	useEffect(() => {
		const thTexts: string[] = [];

		const parseOptions: HTMLReactParserOptions = {

			replace: (domNode) => {

				if (domNode instanceof Element && domNode.attribs) {

					if (domNode.type === "tag" && domNode.name === "table") {
						const tbody: any = domNode.children.filter((node: any) => node.type === "tag" && node.name === "tbody")?.[0];

						if (tbody) {
							const tableRows = tbody.children.filter((node: any) => node.type === "tag" && node.name === "tr");
							const firstTableRow = tableRows[0];
							const restTableRows = tableRows.slice(1);

							if (firstTableRow) {
								return (
									<div className="rte-table">
										<table>
											<thead>
												<tr>
													{firstTableRow.children.map((node: any, index: number) => {
														if (node.type === "tag" && node.name === "td") {
															const text = (node.children?.[0] as any)?.data;

															thTexts.push(text);

															return (
																<th key={`th_${index}`}>{text}</th>
															)
														}
													}).filter(Boolean)}
												</tr>
											</thead>
											<tbody>
												{restTableRows.map((node: any, index: number) => {
													let tdIndex = 0;

													return (
														<tr key={`tr_${index}`}>
															{node.children.map((childNode: any, childIndex: number) => {
																if (childNode.type === "tag" && childNode.name === "td") {
																	tdIndex++;

																	return (
																		<td data-label={thTexts[tdIndex - 1]} key={`td_${childIndex}`}>{domToReact(childNode.children)}</td>
																	)
																}
															}).filter(Boolean)}
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								)
							}
						}
					}
				}
			}
		};

		setRichTextComponent(parse(props.field?.value || "", parseOptions) as React.JSX.Element);
	}, [])

	return richTextComponent;
};

export default TableRichTextWrapper;