import React, { FC, useContext } from "react";
import { HygieneNavigationProps } from "../generated-types";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "~/foundation/Components/Link";
import { useSitecoreContext } from "~/foundation/Jss";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { withNavigationTheme } from "~/foundation/Theme/enhancers/withNavigationTheme";
import { LanguageNavigation } from "~/foundation/Components/LanguageNavigation";
import { GlobalContacts } from "~/foundation/Components/GlobalContacts";

export const critical = true;

const HygieneNavigation: FC<HygieneNavigationProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
	const [theme] = useContext(ColorThemeContext);
	const languages = sitecoreContext?.custom.settings?.languages;
	const navItems = rendering.customData?.menuItems?.find(x => x.menuItemChildren)?.menuItemChildren?.filter(x => x.url);
	const isLargeViewport = useBreakpointValue({ base: false, lg: true }, { ssr: true });
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<>
			{(languages || navItems) &&
				<nav>
					<Flex
						flexFlow="row nowrap"
						justifyContent={["space-around", null, "flex-start"]}
						alignItems="center"
						sx={{ ".mobile-hygiene &": {
							justifyContent: "space-between",
						}
						}}
					>
						<LanguageNavigation useTheme={true} />
						{navItems &&
							<Box ps="8">
								{navItems.map((menuItem, index) =>
									<Link
										key={index}
										to={menuItem.url!}
										size="sm"
										color={theme.colors.header.hygieneGlobalContacts}
										transitionDuration=".750s"
										transitionTimingFunction="ease-in-out"
										transitionProperty="color"
										fontSize="xs"
										fontWeight="400"
										lineHeight="base"
										_hover={{
											color: "primary.aramcoBlue",
										}}
										dataLayer={() => ({
											event: "GAevent",
											event_type: "click",
											event_name: "hygiene_navigation",
											type: "page",
											text: menuItem.title,
											url: menuItem.url
										})}
									>
										{menuItem.title}
									</Link>
								)}
							</Box>
						}
						{isLargeViewport && (
							<Box m={isRtl ? "0 auto 0 0" : "0 0 0 auto"}>
								<GlobalContacts />
							</Box>
						)}
					</Flex>
				</nav>
			}
		</>
	);
};

export default withNavigationTheme({ fallbackToPageTheme: true })(HygieneNavigation);