import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { selectAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({

});

export const Select = defineMultiStyleConfig({ baseStyle })
