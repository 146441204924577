export const getCookie = (name: string) => {
	return document.cookie.split("; ").reduce((r, v) => {
		const parts = v.split("=");
		return parts[0] === name ? decodeURIComponent(parts[1]) : r;
	}, "");
};

export const getFullCookie = (name: string) => {
	return document.cookie.split("; ").reduce((r, v) => {
		const parts = v.split("=");
		return parts[0] === name ? decodeURIComponent(parts as never) : r;
	}, "");
};

export const setCookie = (name: string, value: string, expireDaysFromToday = 7, options: Record<string, never> = {}) => {
	const optionsWithDefaults = {
		days: expireDaysFromToday,
		path: "/",
		...options
	};

	const expires = new Date(
		Date.now() + optionsWithDefaults.days * 864e5
	).toUTCString();

	document.cookie =
		name +
		"=" +
		encodeURIComponent(value) +
		"; expires=" +
		expires +
		"; path=" +
		optionsWithDefaults.path;
};

export const getOneTrustTargetingConsent = () => {
	const targetingGroup = "C0004";
	let targetingConsent = false;

	const oneTrustCookie = getFullCookie("OptanonConsent");

	if (oneTrustCookie && oneTrustCookie.includes(`${targetingGroup}:1`)) {
		targetingConsent = true;
	}

	return targetingConsent;
}

export const getRestrictedAccessCookie = () => {
	return getCookie("aramco_restricted_access_allow");
}
