import React, { FC } from "react";
import config, { SitecoreConfig } from "~/temp/config";

export const SitecoreConfigContext = React.createContext<SitecoreConfig>(config);

type SitecoreConfigProviderProps = {
	sitecoreSiteName?: string;
	children: React.ReactNode;
}

export const SitecoreConfigProvider: FC<SitecoreConfigProviderProps> = ({ sitecoreSiteName, children }) => {

	const value = config;

	if (sitecoreSiteName) {
		value.sitecoreSiteName = sitecoreSiteName;
	}

	return (
		<SitecoreConfigContext.Provider value={value}>
			{children}
		</SitecoreConfigContext.Provider>
	)
}