import React, { FC } from "react";
import { RouteData, SitecoreContextValue } from "~/foundation/Jss";
import constants from "~/foundation/SitecoreContent/constants";
import { ArticleSchema } from "./ArticleSchema";
import { DefaultSchema } from "./DefaultSchema";
import { HomeSchema } from "./HomeSchema";
import { MagazineArticleSchema } from "./MagazineArticleSchema";
import { HubSchema } from "./HubSchema";
import { AffiliateSiteHomeSchema } from "./AffiliateSiteHomeSchema";
import { AffiliateSitePageSchema } from "./AffiliateSitePageSchema";
import { LegacyArticleSchema } from "./LegacyArticleSchema";

type RenderSchemaProps = {
	route: RouteData;
	context: SitecoreContextValue;
};

const getResolveImageUrl = (hostname: string) => {

	return (imagePath?: string) => {
		if (!imagePath) {
			return null;
		}

		if (imagePath.startsWith("/")) {
			return hostname + imagePath;
		}

		return imagePath;
	}
}

export const RenderSchema: FC<RenderSchemaProps> = ({ route, context }) => {
	const { homeTemplateId, articleTemplateId, legacyArticleTemplateId, magazineArticleTemplateId, articleHubTemplateId, magazineHubTemplateId, publicationHubTemplateId } = constants.templateIds.pageTypes;
	const resolveImageUrl = getResolveImageUrl(context.custom.hostname)

	const isAffiliateSite = context.custom.settings.siteNavigation?.showSiteNavigation;
	if (isAffiliateSite) {
		if (route.templateId === homeTemplateId) {
			return <AffiliateSiteHomeSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
		}

		return <AffiliateSitePageSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
	}

	if (route.templateId === homeTemplateId) {
		return <HomeSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
	}

	if (route.templateId === legacyArticleTemplateId) {
		return <LegacyArticleSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
	}

	if (route.templateId === articleTemplateId) {
		return <ArticleSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
	}

	if (route.templateId === magazineArticleTemplateId) {
		return <MagazineArticleSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
	}

	if ([articleHubTemplateId, magazineHubTemplateId, publicationHubTemplateId].includes(route.templateId!)) {
		return <HubSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />;
	}

	return <DefaultSchema route={route} context={context} resolveImageUrl={resolveImageUrl} />
}