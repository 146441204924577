import { ComponentStyleConfig } from "@chakra-ui/react";

export const Popover = (): Partial<ComponentStyleConfig> => ({
	baseStyle: {
		popper: {
			color: "primary.darkGrey",
		},
		header: {
			borderBottomWidth: 0
		}
	},
});