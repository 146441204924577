import React, { FC, useState } from "react";
import { CardProps } from "../generated-types";
import { AspectRatio, Box, Flex, Text as ChakraText, useMediaQuery } from "@chakra-ui/react";
import { mediaApi, sc, useSitecoreContext } from "~/foundation/Jss";
import LinkIconWrapper from "~/foundation/Components/Link/LinkIconWrapper";
import { sizes as LinkSizes, getLinkVariants } from "~/foundation/Theme/components/Link";
import { useNavigate } from "react-router-dom";
import { imageSizes, themable } from "~/foundation/Theme";
import { Cursor } from "./Cursor";
import * as variantThemes from "./variants";
import { ExtendedBox } from "~/foundation/Components/ExtendedBox";
import { ChakraRouterLink } from "~/foundation/Components/Link/ChakraRouterLink";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

export const critical = true;

const Card: FC<CardProps> = ({ fields }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
	const linkVariants = getLinkVariants(isRtl);
	const cardClickDelay = 400;
	const imageSrc = fields?.image?.value?.src;
	const imageAlt = fields?.image?.value?.alt;
	const navigate = useNavigate();
	const [cursorActive, setCursorActive] = useState(false);
	const [customCursor, setCustomCursor] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({
		x: 0,
		y: 0
	});

	const calcPosition = (target: HTMLAnchorElement, x: number, y: number) => {
		const rect = target.getBoundingClientRect();

		setCursorPosition({
			x: x - rect.left,
			y: y - rect.top,
		})
	}

	const cardEnter = (event: React.MouseEvent) => {
		calcPosition(event.currentTarget as HTMLAnchorElement, event.clientX, event.clientY);
		setCustomCursor(true);
	}

	const onMouseMove = (event: React.MouseEvent) => {
		calcPosition(event.currentTarget as HTMLAnchorElement, event.clientX, event.clientY);
	}

	const cardLeave = () => setCustomCursor(false);

	return (
		<>
			{(fields?.link?.value?.href || sitecoreContext.pageEditing) &&
				<ExtendedBox
					overflow="hidden"
					position="relative"
					variant="themed"
					borderRadius="lg"
					cursor="grab"
				>
					{isLgBreakpointOrAbove &&
						<Cursor {...cursorPosition} useCustomCursor={customCursor} active={cursorActive} delay={cardClickDelay} bg={fields?.image?.value?.src ? "white" : "currentColor" }/>
					}
					<AspectRatio ratio={2 / 3}>
						<ChakraRouterLink
							variant="unstyled"
							display="block"
							as={sitecoreContext.pageEditing ? "span" : "a"}
							{...(!sitecoreContext.pageEditing && {
								onMouseEnter: cardEnter,
								onMouseMove: onMouseMove,
								onMouseLeave: cardLeave,
								to: fields?.link?.value?.href,
								isExternal: fields?.link?.value?.linktype === "external" ? true : false,
								onClick: (event) => {
									event.preventDefault();
									setCursorActive(true);
									setTimeout(() => {
										if (fields?.link?.value?.href) {
											if (fields?.link?.value?.linktype === "external"){
												window.open(fields?.link?.value?.href, '_blank');
											} else {
												const [pathname, search] = fields.link.value.href.split("?");
												navigate({ pathname, search });
											}
										}
									}, cardClickDelay)
								}
							})}
						>
							{(sitecoreContext.pageEditing || imageSrc) &&
								<Box position="absolute" top="0" bottom="0" left="0" right="0" zIndex="0" sx={imageSrc ? {
									'&:after': {
										content: `""`,
										position: 'absolute',
										top: "0",
										bgColor: 'overlay.blackAlpha',
										h: '100%',
										w: '100%',
									} } : {}
								}>
									{imageSrc && (
										<LazyImage
											src={mediaApi.updateImageUrl(imageSrc, imageSizes.none)}
											sizes="(min-width: 1440px) 427px, 315px"
											data-splide-lazy-srcset={mediaApi.getSrcSet(imageSrc, [imageSizes.card.portrait.md, imageSizes.card.portrait.lg])}
											alt={imageAlt}
											objectPosition="center center"
											objectFit="cover"
											w="100%"
											h="100%"
										/>
									)}
								</Box>
							}
							{/*Color themes are overridden if card contains image */}
							<Box h="100%" position="relative" zIndex={1} flexGrow="1" color={imageSrc ? "white" : "inherit"}>
								<Flex h="100%" p="6" flexDirection="column" justifyContent="space-between">
									<Box>
										{(fields?.trumpet?.value || sitecoreContext.pageEditing) &&
											<ChakraText variant="overline" mb="4">
												<sc.Text field={fields?.trumpet}/>
											</ChakraText>
										}
										{(fields?.title?.value || sitecoreContext.pageEditing) &&
											<ChakraText
												size="xs"
												fontSize="1.25rem"
												lineHeight="1.75rem" //override h6 mobile to match h6 desktop
												noOfLines={7}
											>
												<sc.Text field={fields?.title}/>
											</ChakraText>
										}
									</Box>
									{sitecoreContext.pageEditing
										?
										<ExtendedBox alignSelf="flex-end" variant="themed-link">
											<sc.Link field={fields?.link} variant="primary" size="md" color={imageSrc ? "white" : "inherit"} />
										</ExtendedBox>
										:
										<ExtendedBox sx={linkVariants.primary} alignSelf="flex-end" variant="themed-link">
											<Box className="icon-container" sx={LinkSizes.sm[".icon-container"]} color={imageSrc ? "white" : "inherit"}>
												<LinkIconWrapper linkValue={fields?.link?.value} w="icons.md" rtl={isRtl} />
											</Box>
										</ExtendedBox>
									}
								</Flex>
							</Box>
						</ChakraRouterLink>
					</AspectRatio>
				</ExtendedBox>
			}
		</>
	);
};

export default themable({ variantThemes })(Card);
