import React, { FC } from "react";
import { Text, useSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type TextProps as SitecoreTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text';
import Balancer from "react-wrap-balancer";

type TextProps = SitecoreTextProps & {
	balance?: boolean;
	tag?: React.ElementType
}

const TextWrapper: FC<TextProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	if (props.balance && !sitecoreContext.pageEditing) {
		return (
			<Balancer as={props.tag || "span"}>
				{props.field?.value}
			</Balancer>
		);
	}

	return <Text {...props} />;
};

export default TextWrapper;