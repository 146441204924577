import React, { FC } from "react";
import { Schema } from "~/foundation/Tracking/Schema";
import { RouteData, SitecoreContextValue } from "~/foundation/Jss";
import { ArticlePage } from "../generated-types";

type AffiliateSitePageSchema = {
	route: RouteData<ArticlePage>;
	context: SitecoreContextValue;
	resolveImageUrl: (imagePath?: string) => string | null;
}

export const AffiliateSitePageSchema: FC<AffiliateSitePageSchema> = ({ route, context, resolveImageUrl }) => {
	return (
		<Schema schema={{
			"@context": "https://schema.org",
			"@type": "WebPage",
			"@id": `${context.custom.absoluteUrl}#WebPage`,
			url: context.custom.absoluteUrl,
			name: route.fields?.pageTitle?.value || route.fields?.metaTitle?.value || "Saudi Aramco",
			description: route.fields?.metaDescription?.value,
			image: resolveImageUrl(route.fields?.metaImage?.value?.src),
			breadcrumb: {
				"@type": "BreadcrumbList",
				itemListElement: (context.custom.breadcrumbItems.items || []).slice(1).map((item, index) => ({
					"@type": "ListItem",
					position: index + 1,
					item: {
						"@id": `${item.url}#WebPage`,
						name: item.title,
					}
				}))
			},
			isPartOf: {
				"@type": "WebSite",
				"@id": `${context.custom.hostname}#WebSite`,
				url: context.custom.hostname,
				name: context.custom.settings.siteName,
				publisher: {
					"@type": "Organization",
					"@id": `${context.custom.hostname}#Organization`
				}
			}
		}} />
	);
}