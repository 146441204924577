import React, { FC, useEffect } from "react";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { Box, CloseButton, Slide, useMediaQuery } from "@chakra-ui/react";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";
import { useLocation } from "react-router-dom";

type SlideInOverlayProps = {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	isRtl: boolean;
	largeContentPadding?: boolean;
}

export const SlideInOverlay: FC<SlideInOverlayProps> = ({ isOpen, onClose, children, isRtl, largeContentPadding = true, ...props }) => {
	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
	const location = useLocation();

	useEffect(() => {
		onClose();
	}, [location]);

	useEffect(() => {
		const bodyElement = document.body;

		bodyElement.style.height = isOpen ? "100%" : "";
		bodyElement.style.overflow = isOpen ? "hidden" : "";
	}, [isOpen])

	const transition = {
		exit: {
			ease: [.35,1,.45,1],
			duration: isLgBreakpointOrAbove ? 1.5 : .35
		},
		enter: {
			ease: [.35,1,.45,1],
			duration: .35
		}
	}

	const bgMotionVariants = {
		open: { opacity: 1, display: "block" },
		close: { opacity: 0,
			transitionEnd: {
				display: "none",
			}
		},
	}

	const bgTransition = {
		default: {
			ease: [.35,1,.45,1],
			duration: .5
		},
	}

	return (
		<Box {...props}>
			<MotionBox
				position="fixed"
				animate={isOpen ? "open" : "close"}
				variants={bgMotionVariants}
				initial="close"
				transition={bgTransition}
				sx={{
					opacity: "0",
					bg: "overlay.darkGreyAlpha",
					h: "100%",
					w: "100%",
					top: 0,
					left: 0,
					zIndex: 2
				}}
			/>
			<Slide
				direction={isRtl ? "left" : "right"}
				in={isOpen}
				transition={transition}
				style={{
					zIndex: 3,
					overflow: "auto",
					willChange: "transform",
					backfaceVisibility: "hidden",
				}}
			>
				<Box position="absolute" h="100%" w="100%" zIndex="-1" onClick={onClose}/>
				<Box
					position="fixed"
					right={isRtl ? "auto" : 0}
					left={isRtl ? 0 : "auto"}
					top={[0, null, null, "50%"]}
					transform={[null, null, null, "translateY(-50%)"]}
					px={largeContentPadding ? ["var(--chakra-sizes-pageMarginSM)", null, null, "16"] : ["1.5rem", null, null, "2rem"]}
					pt={largeContentPadding ? ["8", null, null, "12"] : ["1.5rem", null, null, "2rem"]}
					pb={largeContentPadding ? ["6", null, null, "8"] : ["1.5rem", null, null, "2rem"]}
					bg="primary.white"
					borderLeftRadius={isRtl ? 0 : [null, null, null, "lg"]}
					borderRightRadius={isRtl ? [null, null, null, "lg"] : 0}
					width={["100%", null, null, "31.125rem"]}
				>
					<Box
						position="absolute"
						top={["1.3125rem", null, null, "50%"]}
						transform={isRtl ? [null, null, null, "translateX(100%) translateY(-50%)"] : [null, null, null, "translateX(-100%) translateY(-50%)"]}
						bg={[null, null, null, "primary.aramcoLinkBlue"]}
						color={["primary.aramcoLinkBlue", null, null,"primary.white"]}
						borderLeftRadius={isRtl ? 0 : [null, null, null, "50%"]}
						borderRightRadius={isRtl ? [null, null, null, "50%"] : 0}
						left={isRtl ? ["1rem", null, null, "auto"] : [null, null, null, "0"]}
						right={isRtl ? [null, null, null, "0"] : ["1rem", null, null, "auto"]}
						p={[".5rem", null, null, "1rem"]}
					>
						<CloseButton
							onClick={onClose}
							position="relative"
							w={["2.1875rem", null, null, "2.8125rem"]}
							h={["2.1875rem", null, null, "2.8125rem"]}
							zIndex="1"
							border="2px solid"
							borderColor={["primary.aramcoLinkBlue", null, null,"primary.white"]}
							borderRadius="50%"
							transitionDuration=".25s"
							transitionProperty="transform"
							transitionTimingFunction="aramcoCubic"
							_hover={{
								transform: [null, null, null, "rotate(45deg)"]
							}}
							sx={{
								"svg": {
									w: [".75rem", null, null, "1rem"],
									h: [".75rem", null, null, "1rem"]
								},
								willChange: "transform",
								backfaceVisibility: "hidden",
							}}
						/>
					</Box>
					{children}
				</Box>
			</Slide>
		</Box>
	)
}