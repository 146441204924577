import React, { useContext, useEffect } from "react";
import { SuitcaseContext } from "./SuitcaseStore";
import { generateZipWithFilesFromUrls, saveAs } from "~/foundation/Zip";
import { Box, Flex, Text as ChakraText, Link as ChakraLink, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { Icon } from "~/foundation/Components/Icon";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { AnimatePresence } from "framer-motion";
import { useSitecoreContext } from "~/foundation/Jss";
import { format as formatDate } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { arSA } from "date-fns/locale";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { CustomTooltip } from "~/foundation/Components/CustomTooltip";
import { Separator } from "~/foundation/Components/Separator";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const Suitcase = () => {
	const [suitcase, dispatch] = useContext(SuitcaseContext);
	const { getDisclosureProps, getButtonProps, isOpen, onClose } = useDisclosure();
	const buttonProps = getButtonProps();
	const disclosureProps = getDisclosureProps();
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [t] = useTranslation<GlobalDictionary>();
	const now = new Date();

	const downloadFiles = async (urls: string[]) => {
		const blob = await generateZipWithFilesFromUrls("blob", urls);
		const date = formatDate(
			utcToZonedTime(now, localTimeZone),
			sitecoreContext.custom.settings.dateFormat || "MMMM dd, yyyy",
			{ locale: sitecoreContext.custom.settings.isRtl ? arSA : undefined }
		);

		if (blob) {
			await saveAs(blob, `${urls.length} ${urls.length > 1 ? t("general.labels.files") : t("general.labels.file")} (from Aramco.com, ${date}).zip`);
		}
	};

	const downloadSuitcase = async () => {
		const urls = suitcase.map(x => x.url);

		pushDataLayer(() => ({
			event: "GAevent",
			event_type: "click",
			event_name: "multi_file_suitcase",
			type: "download as zip",
			count: urls.length
		}));

		await downloadFiles(urls);
	};

	const motionVariants = {
		initial: {
			bottom: "-4rem"
		},
		exit: {
			bottom: "-30rem"
		},
		active: {
			bottom: "0rem"
		}
	}

	const transition = {
		default: {
			ease: [.35, 1, .45, 1],
			duration: .5,
		}
	}

	useEffect(() => {
		if (suitcase.length === 0) {
			onClose();
		}
	}, [suitcase.length]);

	return (
		<AnimatePresence>
			{suitcase.length > 0 && (
				<MotionBox
					position="fixed"
					bottom="0"
					top="auto"
					w="100%"
					zIndex="1"
					variants={motionVariants}
					transition={transition}
					initial={motionVariants.initial}
					exit={motionVariants.exit}
					animate={motionVariants.active}
				>
					<ContentWrapper position="relative" py={["0", null]}>
						<Box
							position="absolute"
							bottom="0"
							left={isRtl ? "0" : "auto"}
							right={isRtl ? "auto" : "0"}
							w={["calc( 100% - 8rem )","suitCaseWidth"]}
							mx={["var(--chakra-sizes-pageMarginSM)", null, "16", null, '32']}
							transitionProperty="transform"
							transitionTimingFunction="aramcoCubic"
							transitionDuration=".5s"
							transform={isOpen ? "translateY(0)" : "translateY(calc(100% - 4rem))"}
						>
							<Box>
								<button {...buttonProps} style={{ width: "100%" }}>
									<Flex
										bg="primary.aramcoBlue"
										borderRadius="lg"
										borderBottomRadius="0"
										px="6"
										py="0.875rem"
										color="primary.white"
										justifyContent="space-between">
										<ChakraText variant="footerHeading">
											{t("general.suitcase.myDownloads")}
											<ChakraText variant="footerHeading" as="span" ps="2">({suitcase.length})</ChakraText>
										</ChakraText>
										<Icon
											variant={isOpen ? "Down" : "Up"}
											w="icons.md"
										/>
									</Flex>
								</button>
							</Box>
							<Flex
								{...disclosureProps}
								flexDir="column"
								bg="primary.white"
								alignItems="flex-start"
								maxHeight="17.2rem"
								overflowY="auto"
								{...isOpen && {
									"data-tracking-type": "visibility",
									"data-tracking-id": "multi_file_suitcase",
									"data-tracking-data": JSON.stringify({ type: "open suitcase", count: suitcase.length }),
								}}
							>
								{suitcase.map((item, index) => (
									<Flex
										key={index}
										justifyContent="space-between"
										alignSelf="stretch"
										mt="0"
										ps="6"
										py="4"
										borderWidth="0 0 1px"
										borderColor="primary.lightGrey">
										<CustomTooltip label={t("general.suitcase.viewFile")}>
											<ChakraLink
												variant="unstyled"
												aria-label={t("general.suitcase.viewFile")}
												title={t("general.suitcase.viewFile")}
												target="_blank"
												{...(!sitecoreContext.pageEditing && {
													href: item.url,
												})}
											>
												<ChakraText variant="footerBody" color="primary.aramcoLinkBlue">{item.name}</ChakraText>
												<Box>
													<ChakraText as="span" variant="mediaSpec" sx={{ direction: "ltr" }}>{!isRtl && "."}{item.type?.toLowerCase()}{isRtl && "."}</ChakraText>
													<ChakraText
														as="span"
														variant="mediaSpec"
														ps="2"
														pe="1.5"><Separator h=".5rem" /></ChakraText>
													<ChakraText as="span" variant="mediaSpec">{item.size}</ChakraText>
												</Box>
											</ChakraLink>
										</CustomTooltip>
										<CustomTooltip label={t("general.suitcase.removeFileFromSuitcase")}>
											<button
												onClick={() => dispatch({ type: "REMOVE", payload: item.url })}
												style={{ paddingInline: "1.5rem", lineHeight: "1rem" }}
												aria-label={t("general.suitcase.removeFileFromSuitcase")}
												title={t("general.suitcase.removeFileFromSuitcase")}>
												<Icon
													variant="Close"
													w="icons.md"
													color="primary.aramcoLinkBlue"
												/>
											</button>
										</CustomTooltip>
									</Flex>
								))}
							</Flex>
							<Flex p="6" bg="primary.brightGrey" color="primary.aramcoBlue" justifyContent="space-between">
								<CustomTooltip label={t("general.suitcase.removeAllFilesFromSuitcase")}>
									<button
										onClick={() => dispatch({ type: "CLEAR" })}
										aria-label={t("general.suitcase.removeAllFilesFromSuitcase")}>
										<ChakraText variant="footerBody">{t("general.labels.clear")}</ChakraText>
									</button>
								</CustomTooltip>
								<CustomTooltip label={t("general.suitcase.downloadAsZip")}>
									<button
										onClick={downloadSuitcase}
										aria-label={t("general.suitcase.downloadAsZip")}>
										<Flex>
											<ChakraText variant="footerBody">{t("general.suitcase.downloadAsZip")}</ChakraText>
											<Icon
												variant="Download"
												w="icons.md"
												ms="2"
											/>
										</Flex>
									</button>
								</CustomTooltip>
							</Flex>
						</Box>
					</ContentWrapper>
				</MotionBox>
			)}
		</AnimatePresence>
	);
};