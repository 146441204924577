import { ComponentStyleConfig } from "@chakra-ui/react";
import { ExtendTheme } from "~/foundation/Theme";

export default <Partial<ExtendTheme>>{
	name: "Default",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "#F0F0F0", //Todo - ask Jesper - add to colors.ts or change to another color?
					color: "primary.text",
				},
				"themed-link": {
					color: "primary.aramcoLinkBlue",
				},
			}
		}
	}
};

export const white = <Partial<ExtendTheme>>{
	name: "White",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.white",
					color: "primary.aramcoBlue",
				},
				"themed-link": {
					color: "primary.aramcoLinkBlue",
				},
			}
		}
	}
};

export const aramcoBlue = <Partial<ExtendTheme>>{
	name: "Aramco Blue",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.aramcoBlue",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const darkGreen = <Partial<ExtendTheme>>{
	name: "Dark Green",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.darkGreen",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};