import constants from "~/foundation/SitecoreContent/constants";
import legacyConstants from "~/feature/Legacy/constants";
import { ImageFieldValue, RouteData } from "~/foundation/Jss";
import { ArticlePage, MagazineArticlePage, NewsArticleLegacy, SectionPage } from "./generated-types";

export function validateMetaDataString(str: string | undefined){
	return str !== "$name" ? str : undefined;
}

export function getPageSpecificMetaTitle(route: RouteData<unknown>): string | undefined {
	let pageSpecificMetaTitle: string | number | undefined;
	if (route.templateId === constants.templateIds.pageTypes.sectionPageTemplateId) {
		pageSpecificMetaTitle = (route as RouteData<SectionPage>).fields?.headline?.value;
	}
	if (route.templateId === constants.templateIds.pageTypes.articleTemplateId) {
		pageSpecificMetaTitle = (route as RouteData<ArticlePage>).fields?.title?.value;
	}
	if (route.templateId === constants.templateIds.pageTypes.magazineArticleTemplateId) {
		pageSpecificMetaTitle = (route as RouteData<MagazineArticlePage>).fields?.title?.value;
	}
	if (route.templateId === legacyConstants.newsArticleTemplateId) {
		pageSpecificMetaTitle = (route as RouteData<NewsArticleLegacy>).fields?.title?.value;
	}

	return pageSpecificMetaTitle?.toString();
}

export function getPageSpecificMetaDescription(route: RouteData<unknown>): string | undefined {
	let pageSpecificMetaDescription: string | number | undefined;
	if (route.templateId === constants.templateIds.pageTypes.articleTemplateId) {
		pageSpecificMetaDescription = (route as RouteData<ArticlePage>).fields?.teaser?.value;
	}
	if (route.templateId === constants.templateIds.pageTypes.magazineArticleTemplateId) {
		pageSpecificMetaDescription = (route as RouteData<MagazineArticlePage>).fields?.teaser?.value;
	}
	if (route.templateId === legacyConstants.newsArticleTemplateId) {
		pageSpecificMetaDescription = (route as RouteData<NewsArticleLegacy>).fields?.description?.value;
	}

	return pageSpecificMetaDescription?.toString();
}

export function getPageSpecificMetaImage(route: RouteData<unknown>) {
	let pageSpecificMetaImage: ImageFieldValue | undefined;
	if (route.templateId === constants.templateIds.pageTypes.sectionPageTemplateId) {
		pageSpecificMetaImage = (route as RouteData<SectionPage>).fields?.image?.value;
	}
	if (route.templateId === constants.templateIds.pageTypes.articleTemplateId) {
		pageSpecificMetaImage = (route as RouteData<ArticlePage>).fields?.image?.value;
	}
	if (route.templateId === constants.templateIds.pageTypes.magazineArticleTemplateId) {
		pageSpecificMetaImage = (route as RouteData<MagazineArticlePage>).fields?.image?.value;
	}
	if (route.templateId === legacyConstants.newsArticleTemplateId) {
		pageSpecificMetaImage = (route as RouteData<NewsArticleLegacy>).fields?.image?.value;
	}

	return pageSpecificMetaImage;
}