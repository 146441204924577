export const keyFrames = (isRtl: boolean) => ({
	keyFrames: {
		"@keyframes hoverIn": {
			from: {
				transform: isRtl ? "translateX(105%)" : "translateX(-105%)",
			},
			to: {
				transform: "translateX(0%)",
			}
		},
		"@keyframes hoverOut": {
			from: {
				transform: "translateX(0)",
			},
			to: {
				transform: isRtl ? "translateX(-105%)" : "translateX(105%)",
			}
		},
		"@keyframes skeleton": {
			from: {
				borderColor: "var(--chakra-colors-primary-brightGrey)",
				background: "var(--chakra-colors-primary-brightGrey)",
			},
			to: {
				borderColor: "var(--chakra-colors-primary-neutralGrey)",
				background: "var(--chakra-colors-primary-neutralGrey)",
			}
		}
	}
});

export const animations = (isRtl: boolean, color = "currentColor", duration = ".25s", height = "1px") => ({
	underline: {
		position: "relative",
		background: `linear-gradient(${color}, ${color}) ${isRtl ? "right" : "left"} bottom no-repeat`,
		backgroundSize: `0% ${height}`,
		transition: `background-size ${duration}`,

		"&:hover": {
			backgroundSize: `100% ${height}`
		},
	},
	underlineSwoosh: {
		position: "relative",
		overflow: "hidden",
		"&:after": {
			content: "''",
			display: ["none", null, null, "block"],
			position: "absolute",
			bottom: "0",
			w: "100%",
			h: "1px",
			backgroundColor: color,
			transform: isRtl ? "translateX(105%)" : "translateX(-105%)",
			animationName: "hoverOut",
			animationTimingFunction: "aramcoCubic",
			animationDuration: duration,
		},
		"&:hover": {
			"&:after": {
				transform: "translateX(0)",
				animationName: "hoverIn"
			}
		},
	}
});