import React from "react";
import { Box, BoxProps, forwardRef } from "@chakra-ui/react";

export const ContentWrapper = forwardRef<BoxProps, "div">((props, ref) => (
	<Box
		ref={ref}
		className="content-wrapper"
		maxW={[null, null, "contentWrapperWidthMD", "contentWrapperWidthLG", "contentWrapperWidthXL", "contentWrapperWidth2XL"]}
		mx="auto"
		px={["var(--chakra-sizes-pageMarginSM)", null, "16", null, "32"]}
		py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
		{...props}
	/>
));