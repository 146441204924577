export default {
	templateIds: {
		pageTypes: {
			homeTemplateId: "abb38766-58b5-4839-bf4e-7e929a379d70",
			articleTemplateId: "80250b23-302d-4418-8f5e-e00e9555a1a8",
			legacyArticleTemplateId: "e84cc91e-bcf9-4695-a0b8-a9dc20b0a9f4",
			magazineArticleTemplateId: "db851f06-34e8-48e1-b5c3-38b169f5a962",
			articleHubTemplateId: "c91f636f-00b4-4fd8-828c-3e34b15886b5",
			magazineHubTemplateId: "e8f1dcb7-c53c-4c15-9929-4ea9a1d41835",
			publicationHubTemplateId: "3853372d-effb-4ffb-99e2-91d9908a28c7",
			sectionPageTemplateId: "acb5915b-9bd2-496b-9c63-92889e5c5fda",
		}
	}
}