import React, { FC, useContext } from "react";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { Box, Flex, Text } from "@chakra-ui/react";
import LinkIconWrapper from "../Link/LinkIconWrapper";
import { ChakraRouterLink } from "../Link/ChakraRouterLink";
import { useSitecoreContext } from "~/foundation/Jss";
import { getLinkVariants } from "~/foundation/Theme/components/Link";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type ComponentLoaderProps = {
	error?: Error | unknown;
	retry?: () => void;
	componentName: string;
}

export const ComponentLoader: FC<ComponentLoaderProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const linkVariants = getLinkVariants(isRtl);
	const [theme] = useContext(ColorThemeContext);
	const [t] = useTranslation<GlobalDictionary>();

	if (props.error) {
		// When the loader has errored
		console.error(props.error);
		return (
			<Flex py="4">
				<Text me="5">{t("general.componentLoader.errorMessage")}</Text>
				<ChakraRouterLink onClick={() => window.location.reload()} variant="secondary" size="md" cursor="pointer">
					<Box sx={linkVariants.secondary} color={theme.colors.ribbon.linkColor}>
						<LinkIconWrapper w="icons.md" rtl={isRtl} me="4"/>
						<span>{t("general.componentLoader.reloadButtonLabel")}</span>
					</Box>
				</ChakraRouterLink>
			</Flex>
		)
	}

	// When the loader has just started
	return (
		<div/>
	)

};