import { keyFrames } from "./animations";
import { splideStyles } from "./splide";

export const getGlobalStyling = (isRtl: boolean) => ({
	global: {
		...keyFrames(isRtl).keyFrames,
		html: {
			//scrollBehavior: "smooth",
		},
		body: {
			fontFamily: 'body',
			color: 'font.base',
			bg: 'primary.white',//'blackAlpha.50',
			lineHeight: 'base',
			'button, a': {
				'&:focus-visible': {
					outlineStyle: "solid",
					outlineWidth: "1px",
					outlineColor: "primary.aramcoBlue",
					outlineOffset: "-1px"
				}
			}
		},
		...splideStyles,
		"div.rte-table": {
			overflowX: "auto",

			"tbody tr": {
				"&:nth-of-type(odd)": {
					background: "primary.brightGrey"
				}
			},

			"td, th": {
				p: "1rem",
				minWidth: ["100%", null, null, "12rem"]
			},

			"th": {
				textAlign: isRtl ? "right" : "left",
			},

			"table": {
				border: "0",
				width: "100%"
			},

			"table thead": {
				border: "none",
				clip: ["rect(0 0 0 0)", null, null, "auto"],
				height: ["1px", null, null, "auto"],
				margin: ["-1px", null, null, "auto"],
				overflow: "hidden",
				padding: "0",
				position: ["absolute", null, null, "static"],
				width: ["1px", null, null, "auto"],
			},

			"table tr": {
				display: ["block", null, null, "table-row"],
				marginBottom: ["2rem", null, null, "0"],
			},

			"table td": {
				borderBottom: ["1px solid", null, null, "none"],
				borderColor: "primary.neutralGrey",
				display: ["flex", null, null, "table-cell"],
				justifyContent: "space-between",
				textAlign: isRtl ? ["left", null, null, "right"] : ["right", null, null, "left"],
				marginBottom: 0,
			},

			"table td::before": {
				content: ["attr(data-label)", null, null, "none"],
				fontWeight: "bold",
				pe: "2rem",
				maxW: "30%",
				minW: "30%",
				textAlign: isRtl ? "right" : "left",
			},

			"table td:last-child": {
				borderBottom: 0,
			}
		},

		".chakra-modal__content-container, .chakra-modal__overlay": {
			zIndex: 4
		},

		".chakra-portal [data-popper-placement]": {
			zIndex: "11" // Topping the overlay
		},

		".animate-page-wrapper.is-loading": {
			".container, .page-footer": {
				opacity: 0
			},
		},

		".container": {
			position: "relative",
			opacity: 1,
			transitionDuration: `.125s`,
			transitionTimingFunction: "aramcoCubic",
			transitionProperty: "opacity",
		},

		"input[type]": {
			"&:focus, &:focus-within, &:focus-visible": {
				boxShadow: "none !important"
			}
		},

		".LazyLoad": {
			position: "relative",

			"&:not(.is-visible)": {
				"&:after": {
					content: "''",
					position: "absolute",
					height: "100%",
					width: "100%",
					animationName: "skeleton",
					animationIterationCount: "infinite",
					animationTimingFunction: "linear",
					animationDuration: "0.8s",
					animationDirection: "alternate",
				}
			}
		},

		"img[loading='lazy']": {
			opacity: 0,
			transition: "all .5s ease-in-out",

			"&.image-loaded": {
				opacity: 1
			},
		},

		".nav--open #ot-sdk-btn-floating.ot-floating-button": {
			zIndex: 0
		}

	}
});