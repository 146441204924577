import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { checkboxAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
	control: {
		borderRadius: "2px",
		border: "2px solid",
		borderColor: "primary.neutralGrey",
		w: "1rem",
		h: "1rem",
		overflow: "hidden"
	},
	icon: {
		color: "primary.white",
		bgColor: "primary.aramcoBlue",
		p: "3px",
		h: "1.1rem",
		w: "1.1rem",
	},
	label: {
		ms: "1rem",
	}
});

const sizes = {
	md: definePartsStyle({
		label: defineStyle({
			fontSize: "xs",
			fontFamily: "body",
			fontWeight: "400",
			lineHeight: "1.125rem",
			letterSpacing: "wider"
		})
	})
}

export const Checkbox = defineMultiStyleConfig({ sizes, baseStyle })
