import React, { FC, useContext, useEffect, useState } from "react";
import { MainNavigationItemModel, MainNavigationProps } from "../generated-types";
import { Box, useDisclosure } from "@chakra-ui/react";
import { default as SubNavigation } from "./SubNavigation";
import { MainNavigationStyling } from "./styles";
import { useSitecoreContext } from "~/foundation/Jss";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { useEventEmitter } from "~/foundation/Events";
import { NavigationEvents } from "../events";
import { withNavigationTheme } from "~/foundation/Theme/enhancers/withNavigationTheme";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

export const critical = true;

const MainNavigation: FC<MainNavigationProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [theme] = useContext(ColorThemeContext);

	const [activeNavChildren, setActiveNavChildren] = useState<MainNavigationItemModel[]>([]);

	const { getDisclosureProps, isOpen, onOpen, onClose } = useDisclosure()
	const disclosureProps = { ...getDisclosureProps(), onClose };

	const ref = React.createRef<HTMLDivElement>();

	const eventEmitter = useEventEmitter<NavigationEvents>("navigation");

	useEffect(() => {
		const bodyElement = document.body;

		bodyElement.classList.toggle("nav--open", isOpen);

		bodyElement.style.height = isOpen ? "100%" : "";
		bodyElement.style.overflow = isOpen ? "hidden" : "";
	}, [isOpen])

	const handleFirstLevelClick = (menuItem: MainNavigationItemModel | undefined) => {
		setActiveNavChildren(menuItem?.menuItemChildren!);
		onOpen();

		eventEmitter.emit("onNavigationOpen", true);
	}

	return (
		<Box as="nav" role="navigation" ref={ref} className={`mainnav ${isOpen ? "mainnav--active" : ""}`} {...MainNavigationStyling(isRtl, theme)}>
			<SubNavigation
				disclosureProps={disclosureProps}
				isOpen={isOpen}
				navChildren={rendering.customData?.menuItems}
				activeNavChildren={activeNavChildren}
				handleFirstLevelClick={handleFirstLevelClick}
				rendering={rendering}
				navRef={ref}
			/>
		</Box>
	);
};

export default withNavigationTheme({ fallbackToPageTheme: true })(MainNavigation);