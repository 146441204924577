/* eslint-disable @typescript-eslint/no-explicit-any */
type LocalStorageItem<T> = {
	value: T;
	expiry: number;
}

/**
 * Set value to a key in localstorage with an expiry date
 * @param key LocalStorage key
 * @param value LocalStorage value
 * @param ttl Time To Live in milliseconds
 */
export const setLocalStorage = <T>(key: string, value: T, ttl: number) => {
	if (typeof localStorage === "undefined") {
		return;
	}

	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item: LocalStorageItem<T> = {
		value: value,
		expiry: now.getTime() + ttl,
	};

	localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorage = <T>(key: string): T | null => {
	if (typeof localStorage === "undefined") {
		return null;
	}

	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr) as LocalStorageItem<T>;
	const now = new Date();

	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
};


export const removeLocalStorage = (key: string): void => {
	if (typeof localStorage === "undefined") {
		return;
	}

	localStorage.removeItem(key);
};