import React, { FC } from "react";
import { Schema } from "~/foundation/Tracking/Schema";
import { ItemReference, RouteData, SitecoreContextValue } from "~/foundation/Jss";
import { NewsArticleLegacy } from "../generated-types";
import { Tag } from "~/foundation/SitecoreContent/generated-types";

type LegacyArticleSchemaProps = {
	route: RouteData<NewsArticleLegacy>;
	context: SitecoreContextValue;
	resolveImageUrl: (imagePath?: string) => string | null;
}

export const LegacyArticleSchema: FC<LegacyArticleSchemaProps> = ({ route, context, resolveImageUrl }) => {

	return (
		<Schema schema={{
			"@context": "https://schema.org",
			"@type": "WebPage",
			"@id": `${context.custom.absoluteUrl}#WebPage`,
			url: context.custom.absoluteUrl,
			name: route.fields?.browserTitle?.value || route.fields?.title?.value || "Article",
			description: route.fields?.metaDescription?.value || "Article",
			image: resolveImageUrl(route.fields?.image?.value?.src),
			breadcrumb: {
				"@type": "BreadcrumbList",
				itemListElement: context.custom.breadcrumbItems.items.slice(1).map((item, index) => ({
					"@type": "ListItem",
					position: index + 1,
					item: {
						name: item.title,
						"@id": `${item.url}#WebPage`
					}
				}))
			},
			mainEntity: {
				"@type": "Article",
				"@id": `${context.custom.absoluteUrl}#Article`,
				headline: route.fields?.title?.value,
				articleBody: route.fields?.description?.value,
				image: [
					route.fields?.image?.value?.src || ""
				],
				datePublished: route.fields?.publishDate?.value,
				about: route.fields?.topicTags?.map((topic: ItemReference<Tag>) => ({
					"@type": "DefinedTerm",
					"@id": `${context.custom.hostname}${context.custom.settings.searchResultPageUrl}?topics=${topic!.name}#DefinedTerm`,
					"name": topic?.fields.title?.value || topic!.name,
					"url": `${context.custom.hostname}${context.custom.settings.searchResultPageUrl}?topics=${topic!.name}`
				})) || []
			},
			isPartOf: {
				"@type": "Website",
				"@id": `${context.custom.hostname}#Website`,
				name: "Aramco",
				url: context.custom.hostname,
				publisher: {
					"@type": "Corporation",
					"@id": `${context.custom.hostname}#Corporation`
				}
			}
		}} />
	);
}