export const fontSizes = (rtl: boolean) => ({
	fontSizes: {
		"6xl": rtl ? "4.5625rem" : "4.3125rem",
		"5xl": rtl ? "4rem" : "3.75rem",
		"4xl": rtl ? "3.5625rem" : "3.3125rem",
		"3xl": rtl ? "2.75rem" : "2.5rem",
		"2xl": rtl ? "2.1875rem" : "1.9375rem",
		"xxl": rtl ? "2.1875rem" : "1.9375rem",
		xl: rtl ? "2rem" : "1.75rem",
		lg: rtl ? "1.75rem" : "1.5rem",
		md: rtl ? "1.5rem" : "1.25rem",
		body: rtl ? "1.375rem" : "1.125rem",
		sm: rtl ? "1.25rem" : "1rem",
		xs: rtl ? "1.125rem" : ".875rem",
		"2xs": rtl ? "1rem" : ".75rem",
	}
})