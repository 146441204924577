import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';

export type AudioPlayerContextData = {
	isAudioPlayerOpen: boolean;
	setIsAudioPlayerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isAudioPlayerInView: boolean;
	setIsAudioPlayerInView: React.Dispatch<React.SetStateAction<boolean>>;
	isPlaying: boolean;
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
	hasAudio: boolean;
	setHasAudio: React.Dispatch<React.SetStateAction<boolean>>;
	isPaused: boolean;
	setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AudioPlayerContext = createContext<AudioPlayerContextData>({} as AudioPlayerContextData);

export const AudioPlayerProvider = ({ children }: PropsWithChildren) => {
	const [isAudioPlayerOpen, setIsAudioPlayerOpen] = useState(false);
	const [isAudioPlayerInView, setIsAudioPlayerInView] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [hasAudio, setHasAudio] = useState(false);

	useEffect(() => {
		if (!isAudioPlayerInView && hasAudio) {
			setIsAudioPlayerOpen(true);
		} else if (!isPlaying && !isPaused && hasAudio) {
			setIsAudioPlayerOpen(false);
		}
	}, [isAudioPlayerInView]);

	useEffect(() => {
		if (isPlaying) {
			setIsPaused(false);
		} else {
			setIsPaused(true);
		}
	}, [isPlaying]);

	const contextValue: AudioPlayerContextData = {
		isAudioPlayerOpen,
		setIsAudioPlayerOpen,
		isAudioPlayerInView,
		setIsAudioPlayerInView,
		isPlaying,
		setIsPlaying,
		isPaused,
		setIsPaused,
		hasAudio,
		setHasAudio
	};

	return (
		<AudioPlayerContext.Provider value={contextValue}>
			{children}
		</AudioPlayerContext.Provider>
	);
};