export const RestrictedAccessStyling = () => ({
	"sx": {
		".restricted-access": {
			"&__button": {
				borderRadius: "xl",
				border: "2px solid",
				borderColor: "primary.aramcoLinkBlue",
				fontSize: "xs",
				fontWeight: "400",
				cursor: "pointer",
				display: "inline-flex",
				justifyContent: "space-between",
				w: "auto",
				h: "auto",
				minW: [null, null, null, "11.25rem"],
				mb: ["1.5rem", null, null, 0],
				py: ".595rem",
				px: "1rem",

				'&:active, &:hover, &:focus': {
					bgColor: "primary.aramcoLinkBlue",
					color: "primary.white",
					textDecoration: 'none',
				},
			},

			"&__select-button": {
				borderRadius: "xl",
				border: "2px solid",
				borderColor: "primary.aramcoLinkBlue",
				fontSize: "xs",
				fontWeight: "400",
				cursor: "pointer",
				display: "inline-flex",
				justifyContent: "space-between",
				w: "auto",
				h: "auto",
				minW: [null, null, null, "11.25rem"],
				mb: ["1.5rem", null, null, 0],
				py: ".595rem",
				px: "1rem"	,
			},

			"&__option": {
				display: 'flex',
				alignItems: 'center',
				justifyContent: "space-between",
				borderRadius: "sm",
				px: "4",
				py: "3",
				transition: "none",
				fontSize: 'xs',
				lineHeight: '1.125rem',
				letterSpacing: 'wider',
				color: "primary.white",

				'&:active, &:hover, &:focus': {
					bgColor: "primary.white",
					color: "primary.text",
					textDecoration: 'none',
				},

			}
		}
	}
});
