import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
	baseStyle: {
		display: "inline-flex",
		fontFamily: 'body',
		fontWeight: '400',
		fontSize: 'md',
		transitionDuration: ".25s",
		transitionTimingFunction: "aramcoCubic",
		transitionProperty: "color, border, background",
		'&:hover, &:active': {
			color: 'primary.aramcoBlue'
		},
		'&:disabled': {
			opacity: '.25',
		}
	},
	sizes: {
		md: {
			fontSize: 'xs',
			lineHeight: '1.3',
			letterSpacing: 'wider',
			h: 'auto',
			minW: '0',
			ps: '0',
			pe: '0',
			'.icon-wrapper': {
				h: '45px',
				w: '45px',
				borderWidth: '2px',
			}
		},
		lg: {
			fontSize: 'md',
			lineHeight: 'base',
			letterSpacing: 'wide',
			h: 'auto',
			minW: '0',
			ps: '0',
			pe: '0',
			'.icon-wrapper': {
				h: '64px',
				w: '64px',
				borderWidth: '2.5px',
			}
		},
		xl: {
			h: 'auto',
			minW: '0',
			ps: '0',
			pe: '0',
			'.icon-wrapper': {
				h: '80px',
				w: '80px',
				borderWidth: '2.5px',
			}
		},
	},
	variants: {
		'primary': {
			'.icon-wrapper': {
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderColor: 'currentColor',
				'svg': {
					fill: 'currentColor',
				},
			},
		},
		'popover': {
			fontSize: "inherit",
			fontWeight: "inherit",
			lineHeight: "inherit",
			verticalAlign: "inherit",
			px: "2",
			borderRadius: "1rem"
		},
		"outline-tag": {
			cursor: "pointer",
			me: "1rem",
			mb: "1rem",
			borderRadius: "xl",
			border: "2px solid",
			px: "1rem",
			py: ".375rem",
			fontSize: "2xs",
			fontWeight: "400",
			fontFamily: "body",
			display: "inline-flex",
			borderColor: "primary.neutralGrey",

			'&:hover, &:active': {
				color: 'primary.aramcoBlue',
				borderColor: "currentColor",
			},
		},
		"outline": {
			cursor: "pointer",
			borderRadius: "xl",
			border: "2px solid",
			px: "1rem",
			py: ".375rem",
			fontSize: "sm",
			fontFamily: "body",
			display: "inline-flex",
			borderColor: "primary.white",

			'&:hover, &:active': {
				color: 'primary.aramcoBlue',
				borderColor: "currentColor",
			},
		},
		//the following are not part of design - just playing around - remove these when playtime is over
		'outline--solid': {
			'.icon': {
				h: ['52px', null, null, '64px'],
				w: ['52px', null, null, '64px'],
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderColor: 'primary.aramcoBlue',
				borderWidth: '3px',
			}
		},
		'with-shadow': {
			bg: 'red.400',
			boxShadow: '0 0 2px 2px #efdfde',
		},
		'outline-gradient-hover': {
			bg: "none",
			h: ['52px', null, null, '64px'],
			fontFamily: 'fonts.heading',
			fontWeight: '400',
			fontSize: ['md', null, null, '2xl'],
			lineHeight: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'start',
			padding: '0',
			'.button__rounded-arrow': {
				strokeDasharray: '200px',
				strokeDashoffset: '200px',
				transition: 'stroke-dashoffset .7s ease-in-out',
				strokeWidth: '2px',
				'_hover': {
					strokeDasharray: '200px',
					strokeDashoffset: '0px',
				},
			},
			'svg path': {
				fill: 'currentColor',
				color: 'currentColor',
			},

		},
		// 5. We can add responsive variants
		sm: {
			bg: 'teal.500',
			fontSize: 'sm',
		},
	},
	// 6. We can overwrite defaultProps
	defaultProps: {
		// size: 'lg', // default is md
		// variant: 'sm', // default is solid
		// colorScheme: 'green', // default is gray
	},
};