import { ComponentStyleConfig } from "@chakra-ui/react";
import { text as ThemeTextStyling } from "./Text";

export const Heading = (rtl: boolean): ComponentStyleConfig => ({
	// The styles all Headings have in common
	baseStyle: {
		fontWeight: '300',
	},
	sizes: {
		//from typography design, https://www.figma.com/file/mNq6YgkFKzhkSEIQB0eNIE/.com%3A-Digital-Style-Guide?node-id=1%3A4
		//Todo: if arabic are different => add ltr condition
		...ThemeTextStyling(rtl).sizes
	},
	// Added custom variant with gradient
	variants: {
		'gradient': {
			display: 'inline-block',
			bgClip: 'text',
			bgGradient: 'var(--chakra-colors-gradients-brand)',
		},
		'blue': {
			color: 'primary.aramcoBlue'
		},
		'green': {
			color: 'primary.aramcoGreen'
		},
		'default': {
			//color: 'font.base',
		},
	},
	// The default size and variant values
	defaultProps: {
		//variant: 'default',
	},
});