import { SitecoreContextValue } from "~/foundation/Jss";
import { PageDarkThemeBase, PageThemeBase } from "../generated-types";
import { ColorTheme, ColorThemeId } from "..";
import { getColorTheme } from "./themable";
import { getDefaultColorTheme } from "../color-themes/default-color-theme";
import { sitecoreContentConstants } from "~/foundation/SitecoreContent";
import { useContext } from "react";
import { ColorThemeContext } from "../ColorThemeContext";

export function usePageTheme(sitecoreContext: SitecoreContextValue<PageThemeBase & PageDarkThemeBase>): ColorTheme {
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const pageColorThemeId = sitecoreContext?.route?.fields?.colorTheme?.value as ColorThemeId | undefined;
	const [theme] = useContext(ColorThemeContext);

	if (sitecoreContext.route?.templateId === sitecoreContentConstants.templateIds.pageTypes.magazineArticleTemplateId) {
		return theme;
	}

	const returnValue = getColorTheme(pageColorThemeId, isRtl) || getDefaultColorTheme(isRtl);

	return returnValue;
}