import React, { FC } from "react";
import { Schema } from "~/foundation/Tracking/Schema";
import { RouteData, SitecoreContextValue } from "~/foundation/Jss";
import { ArticlePage } from "../generated-types";
import { OfficeModel } from "~/foundation/SitecoreContent/generated-types";

type HomeSchemaProps = {
	route: RouteData<ArticlePage>;
	context: SitecoreContextValue;
	resolveImageUrl: (imagePath?: string) => string | null;
}

export const HomeSchema: FC<HomeSchemaProps> = ({ route, context, resolveImageUrl }) => {
	const office: OfficeModel | undefined = (context.custom.settings.offices || [])[0];

	return (
		<Schema schema={{
			"@context": "https://schema.org",
			"@type": "WebPage",
			"@id": `${context.custom.absoluteUrl}#WebPage`,
			url: context.custom.absoluteUrl,
			name: context.custom.settings.siteName || route.fields?.pageTitle?.value || route.fields?.metaTitle?.value || "Home",
			description: route.fields?.metaDescription?.value || "Home",
			mainEntity: {
				"@type": "WebSite",
				"@id": `${context.custom.absoluteUrl}#Website`,
				name: "Aramco",
				url: context.custom.absoluteUrl,
				...(context.custom.settings.searchResultPageUrl && {
					potentialAction: {
						"@type": "SearchAction",
						target: `${context.custom.hostname}${context.custom.settings.searchResultPageUrl}?query={search_term_string}`,
						"query-input": "required name=search_term_string"
					}
				}),
				publisher: [{
					"@type": "Corporation",
					"@id": `${context.custom.hostname}#Corporation`,
					name: "Aramco",
					alternateName: "Saudi Aramco",
					description: "Aramco is one of the world's largest integrated energy and chemicals companies, creating value across the hydrocarbon chain, and delivering societal and economic benefits to people and communities around the globe who rely on the vital energy we supply.", // todo
					logo: {
						"@type": "ImageObject",
						url: resolveImageUrl(context.custom.settings.logoLight),
					},
					url: context.custom.hostname,
					...(office && {
						address: {
							"@type": "PostalAddress",
							streetAddress: office.address,
							postalCode: office.zip,
							addressRegion: office.region,
							addressLocality: office.city,
							addressCountry: office.country
						},
					}),
					sameAs: [
						...context.custom.settings.socialMediaLinks,
						"https://en.wikipedia.org/wiki/Saudi_Aramco",
						"https://www.wikidata.org/wiki/Q679322",
						"https://www.theguardian.com/business/aramco"
					],
				}]
			}
		}} />
	);
}