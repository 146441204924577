/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { extendTheme, ThemeProvider } from "@chakra-ui/react";
import { PageThemeBase, ThemeRenderingParameters } from "../generated-types";
import { useSitecoreContext } from "~/foundation/Jss";
import { getDefaultTheme, themes, ExtendTheme, ColorThemeId, ColorTheme } from "..";
import { getDefaultColorTheme } from "../color-themes/default-color-theme";
import { ColorThemeProvider } from "../ColorThemeContext";
import { sitecoreContentConstants } from "~/foundation/SitecoreContent";

type ThemableProps = {
	params?: ThemeRenderingParameters;
	rendering: {
		componentName: string;
	}
}

export function getColorTheme(themeId: ColorThemeId | undefined, isRtl: boolean): ColorTheme | null {
	let colorTheme: ColorTheme | null = null;
	if (themeId) {
		const colorThemeFn = themes[themeId];
		if (colorThemeFn && typeof colorThemeFn === "function") {
			colorTheme = colorThemeFn(isRtl);
		}
	}

	return colorTheme;
}



type ThemableArgs1 = {
	variantThemes: Record<string, Partial<ExtendTheme>>;
	defaultColorThemeId?: never;
	forcePageColorTheme?: never;
}

type ThemableArgs2 = {
	variantThemes?: Record<string, Partial<ExtendTheme>>;
	defaultColorThemeId: ColorThemeId;
	forcePageColorTheme?: never;
}

type ThemableArgs3 = {
	variantThemes?: Record<string, Partial<ExtendTheme>>;
	defaultColorThemeId?: never;
	forcePageColorTheme: boolean;
}

type ThemableArgs = ThemableArgs1 | ThemableArgs2 | ThemableArgs3;

// https://stackoverflow.com/questions/40510611/typescript-interface-require-one-of-two-properties-to-exist


export function themable(properties?: ThemableArgs) {
	const { variantThemes = {}, defaultColorThemeId = undefined, forcePageColorTheme = false } = properties || {};

	return function ThemableInputComponent<P extends object>(InputComponent: React.ComponentType<P>, inheritColorThemeFromParent: boolean | ((props: P) => boolean) = false) {
		const themable = (props: P & ThemableProps) => {
			const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
			const isRtl = sitecoreContext?.custom.settings.isRtl || false;

			// we override functionality for elements pages, so colorthemes are the same on the whole page
			if (sitecoreContext.route?.templateId === sitecoreContentConstants.templateIds.pageTypes.magazineArticleTemplateId) {
				if (variantThemes && Object.keys(variantThemes).length > 0) {
					let variant = props.params?.variant;
					if (!variant) {
						variant = "default";
					}

					const componentVariantTheme = extendTheme(variantThemes[variant], getDefaultTheme(isRtl));
					if (componentVariantTheme) {
						return (
							<ThemeProvider theme={componentVariantTheme}>
								<InputComponent {...props} />
							</ThemeProvider>
						);
					}
				}

				return <InputComponent {...props} variantThemes={variantThemes} />;
			}

			let shouldInheritColorTheme = false;

			if (typeof inheritColorThemeFromParent === "function") {
				shouldInheritColorTheme = inheritColorThemeFromParent(props);
			} else {
				if (typeof inheritColorThemeFromParent === "boolean") {
					shouldInheritColorTheme = inheritColorThemeFromParent;
				}
			}

			const defaultColorTheme = getDefaultColorTheme(isRtl);

			const pageColorThemeId = sitecoreContext?.route?.fields?.colorTheme?.value as ColorThemeId | undefined;
			const pageColorTheme = getColorTheme(pageColorThemeId, isRtl) || defaultColorTheme;
			const selectedDefaultColorTheme = forcePageColorTheme ? pageColorTheme : getColorTheme(defaultColorThemeId ?? undefined, isRtl);

			const componentColorThemeId = props.params?.backgroundTheme as ColorThemeId | undefined;
			let componentColorTheme: ColorTheme | null = null;
			if (componentColorThemeId) {
				componentColorTheme = getColorTheme(componentColorThemeId, isRtl);
			} else {
				componentColorTheme = selectedDefaultColorTheme
			}

			if (props.params?.usePageTheme === "1") {
				componentColorTheme = pageColorTheme;
			} else {
				componentColorTheme = componentColorTheme || defaultColorTheme
			}

			if (!shouldInheritColorTheme) {
				if (variantThemes && Object.keys(variantThemes).length > 0) {
					let variant = props.params?.variant;
					if (!variant) {
						variant = "default";
					}

					const componentVariantTheme = extendTheme(variantThemes[variant], getDefaultTheme(isRtl));
					if (componentVariantTheme) {
						return (
							<ColorThemeProvider colorTheme={componentColorTheme} componentName={props.rendering.componentName}>
								<ThemeProvider theme={componentVariantTheme}>
									<InputComponent {...props} />
								</ThemeProvider>
							</ColorThemeProvider>
						);
					}
				}
			}

			if (shouldInheritColorTheme) {
				return <InputComponent {...props} variantThemes={variantThemes} />;
			}

			return (
				<ColorThemeProvider colorTheme={componentColorTheme} componentName={props.rendering.componentName}>
					<InputComponent {...props} variantThemes={variantThemes} />
				</ColorThemeProvider>
			);
		}

		themable.displayName = "themable";

		return themable;
	}
}