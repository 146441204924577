import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Button, Flex, Heading, Text as ChakraText, AspectRatio } from "@chakra-ui/react";
import { SlideInOverlay } from "~/foundation/Components/SlideInOverlay";
import { mediaApi, useSitecoreContext } from "~/foundation/Jss";
import { ChakraRouterLink } from "~/foundation/Components/Link/ChakraRouterLink";
import { imageSizes } from "~/foundation/Theme";
import { animations } from "~/foundation/Theme/styles/animations";
import { useNavigate, createSearchParams } from "react-router-dom";
import { SlideInTagSearchContext } from "~/foundation/Search/SlideInTagSearchContext";
import { ISearchResultItemModel } from "../generated-types";
import { searchContentService } from "../services/searchContentService";
import { useTranslation } from "~/foundation/Dictionary";
import { SearchDictionary } from "../dictionary";
import { Loader } from "~/foundation/Components/Loading";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";

export const SlideInTagSearch = () => {
	const { sitecoreContext } = useSitecoreContext();
	const { onOpen, isOpen, onClose, currentTag, setCurrentTag, getDisclosureProps } = useContext(SlideInTagSearchContext);
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const navigate = useNavigate();
	const [relatedPosts, setRelatedPosts] = useState<ISearchResultItemModel[]>([]);
	const linkRef = useRef<HTMLAnchorElement>(null);
	const [t] = useTranslation<SearchDictionary>();
	const nearestHub = sitecoreContext.custom.nearestHub;
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {

		if (currentTag && nearestHub) {

			setLoaded(false);
			onOpen();

			searchContentService.articleSearch(nearestHub.id, { page: 0, topics: currentTag.id, sort: "pubdate|true", pageSize: 3, excludeIds: sitecoreContext.route?.itemId, multisite: false })
				.then(response => {
					setLoaded(true);

					if (response.data?.results) {
						setRelatedPosts(response.data.results);
						return;
					}

					setRelatedPosts([]);
				})
				.catch(error => {
					console.error(error);
					setCurrentTag(null);
					setLoaded(true);
				});
		} else {
			setRelatedPosts([]);
			setLoaded(true);
		}

	}, [currentTag]);

	const closeSlideInOverlay = () => {
		setRelatedPosts([]);
		setCurrentTag(null);
		setLoaded(false);
		onClose();
	}

	useEffect(() => {
		if (isOpen) {
			linkRef.current?.focus();
		}
	}, [isOpen])

	return (
		<SlideInOverlay isRtl={isRtl} isOpen={isOpen} onClose={closeSlideInOverlay} largeContentPadding={false} {...getDisclosureProps()}>
			<Box maxHeight="90vh" overflowY="auto">
				<Button
					as="a"
					ref={linkRef as never}
					href={sitecoreContext.custom.settings.searchResultPageUrl}
					mb={["2rem", null, null, "3rem"]}
					whiteSpace="normal"
					onClick={(event) => {
						event.preventDefault();
						navigate({
							pathname: sitecoreContext.custom.settings.searchResultPageUrl,
							search: createSearchParams({
								topics: currentTag?.title.toLowerCase() ?? ""
							}).toString()
						});
					}}
				>
					<Box display="inline-block" me={["3rem", null, null, "0"]}>
						<Heading
							as="h5"
							fontSize={["xl", null, null, "2xl"]}
							lineHeight={["120%", null, null, "2.625rem"]}
							color="primary.aramcoLinkBlue"
							cursor="pointer"
							display="inline"
							_focus={{ color: "red" }}
							sx={{ ...animations(isRtl, "currentColor", ".35s").underline }}
						>
							{nearestHub?.header.replace("{0}", currentTag?.title || "")}
						</Heading>
					</Box>
				</Button>
				{!loaded
					? <Flex justifyContent="center"><Loader /></Flex>
					: (
						<>
							{relatedPosts.length === 0
								? (
									<Box>
										<ChakraText size="body">{t("search.noResults")}</ChakraText>
									</Box>
								) : (
									<>
										{relatedPosts.map((post, index) => (
											<ChakraRouterLink
												key={index}
												to={post.url}
												display="flex"
												py="4"
												borderTop={index === 0 ? "0" : "1px solid"}
												borderColor="primary.neutralGrey"
											>
												{(post.smallImage && post.smallImage.url) &&
													<AspectRatio
														ratio={1}
														w={["96px", null, null, "145px"]}
														me={["1.5rem", null, null, "2rem"]}
														borderRadius="lg"
														borderBottomRightRadius="0"
														overflow="hidden"
													>
														<LazyImage
															src={mediaApi.updateImageUrl(post.smallImage.url, imageSizes.relatedTags.sm)}
															alt={post.title}
															sizes="(min-width: 1440px) 145px, 96px"
															srcSet={mediaApi.getSrcSet(post.smallImage.url, [imageSizes.relatedTags.sm, imageSizes.relatedTags.md])}
															objectPosition="center center"
															objectFit="cover"
															maxW="100%"
															w="100%"
															h="100%"
															transform="translateZ(0)"
														/>
													</AspectRatio>
												}
												<Flex flexFlow="column" justifyContent="space-between" maxW={["calc(100% - 120px)", null, null, "calc(100% - 177px)"]}>
													<ChakraText as="h4" size="body" mb="2" color="primary.text" noOfLines={3}>
														{post.title}
													</ChakraText>
													<Box
														display="inline-block"
														fontSize="xs"
													>
														<ChakraText
															display="inline"
															sx={{ ...animations(isRtl, "currentColor", ".35s").underline }}
															color="primary.aramcoLinkBlue"
														>
															{t("search.readMore")}
														</ChakraText>
													</Box>
												</Flex>
											</ChakraRouterLink>
										))}
									</>
								)
							}
						</>
					)}
				<Box textAlign="center">
					<Button
						as="a"
						href={sitecoreContext.custom.settings.searchResultPageUrl}
						cursor="pointer"
						display="inline-block"
						fontSize="xs"
						mt="2rem"
						onClick={(event) => {
							event.preventDefault();
							navigate({
								pathname: sitecoreContext.custom.settings.searchResultPageUrl,
								search: createSearchParams({
									topics: currentTag?.title.toLowerCase() ?? ""
								}).toString()
							});
						}}
					>
						<ChakraText
							as="span"
							display="inline"
							sx={{ ...animations(isRtl, "currentColor", ".35s").underline }}
							color="primary.aramcoLinkBlue"
						>
							{t("search.filteredSearchLink")}
						</ChakraText>
					</Button>
				</Box>

			</Box>
		</SlideInOverlay>
	)
}