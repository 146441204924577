import React, { useContext } from "react";
import { forwardRef, useStyleConfig, BoxProps, ThemingProps, Box } from "@chakra-ui/react";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

export type RibbonProps = BoxProps & ThemingProps;

export const Ribbon = forwardRef<RibbonProps, "section">((props, ref) => {
	const [theme] = useContext(ColorThemeContext);
	const { colors } = theme;

	const { variant, ...rest } = props;
	const styles = useStyleConfig("Ribbon", { variant });

	return <Box
		as="section"
		className="ribbon"
		ref={ref}
		transitionDuration=".750s"
		transitionTimingFunction="aramcoCubic"
		transitionProperty="color, background, background-color"
		bg={colors.ribbon.bg}
		color={colors.ribbon.color}
		__css={styles}
		{...rest}
	/>;
});