import { useSitecoreContext } from "~/foundation/Jss";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { Box, Flex, Text as ChakraText, Link as ChakraLink } from "@chakra-ui/react";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import React, { FC, useContext } from "react";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { LanguageNavigationStyles } from "./styles";

type LanguageNavigationProps = {
	useTheme: boolean
}

export const LanguageNavigation: FC<LanguageNavigationProps> = ({ useTheme }) => {
	const { sitecoreContext } = useSitecoreContext<PageThemeBase>();
	const languages = sitecoreContext?.custom.settings?.languages;
	const [theme] = useContext(ColorThemeContext);

	if (!languages) {
		return <></>
	}

	return (
		<Flex alignItems="center" {...LanguageNavigationStyles}>
			{languages.map((language, index: number) => (
				<Box key={index}>
					{language.isSelected
						? (
							<ChakraText
								as="span"
								className={language.languageCode === "ar" ? "language-nav__item language-nav__item--ar" : "language-nav__item language-nav__item--en"}
								size="xxs"
								fontWeight="400"
								lineHeight="base"
								color={[useTheme ? "primary.white" : "primary.aramcoBlue", null, null, useTheme ? theme.colors.header.activeColor : "primary.aramcoBlue"]}>
								{language.title}
							</ChakraText>
						)
						: (
							<ChakraLink
								href={language.languageUrl!}
								onClick={(e) => {
									e.preventDefault();
									pushDataLayer(() => ({
										event: "GAevent",
										event_type: "click",
										event_name: "hygiene_navigation",
										type: "language",
										text: language.title,
										url: language.languageUrl
									}));
									window.location.href = language.languageUrl!;
								}}
								className={language.languageCode === "ar" ? "language-nav__item language-nav__item--active language-nav__item--ar" : "language-nav__item language-nav__item--en language-nav__item--active"}
								size="xxs"
								color={["primary.white", null, null, useTheme ? theme.colors.header.hygieneColor : null]}>
								{language.title}
							</ChakraLink>
						)
					}
					{index < languages.length - 1 &&
							<ChakraText as="span" size="xxs" className="language-nav__item-splitter" px="2" color={["primary.white", null, null, useTheme ? theme.colors.header.hygieneColor : null]}>|</ChakraText>
					}
				</Box>
			)
			)}
		</Flex>
	)
}