import config from "~/temp/config";
import { RestLayoutService } from "@sitecore-jss/sitecore-jss-react";
import { type RestLayoutServiceConfig } from "@sitecore-jss/sitecore-jss/types/layout/rest-layout-service";
import { type CancelTokenSource } from "axios";

export class LayoutServiceFactory {
	create(siteName?: string, cancelTokenSource?: CancelTokenSource) {
		return new RestLayoutService({
			apiHost: config.sitecoreApiHost,
			apiKey: config.sitecoreApiKey,
			siteName: siteName || config.sitecoreSiteName,
			configurationName: "jss",
			cancelTokenSource,
		} as RestLayoutServiceConfig & { cancelTokenSource: CancelTokenSource });
	}
}

export const layoutServiceFactory = new LayoutServiceFactory();
