import React, { FC } from "react";
import { ReactComponent as AramcoA } from "~/foundation/Assets/svg/graphics/a.svg";
import {
	BreadcrumbItem as ChakraBreadcrumbItem,
	BreadcrumbItemProps,
	BreadcrumbLink,
	Flex,
	Box,
	useBreakpointValue,
	useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "~/foundation/Components/Link";
import { Icon } from "~/foundation/Components/Icon";
import type { BreadcrumbItem as SitecoreBreadcrumbItem } from "~/foundation/SitecoreContent/generated-types";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { useSitecoreContext } from "~/foundation/Jss";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

type BreadcrumbExtendedProps = BreadcrumbItemProps & Omit<SitecoreBreadcrumbItem, "children"> & {
	menuItems: SitecoreBreadcrumbItem[];
	prevItems: SitecoreBreadcrumbItem[];
	index: number;
}

export const BreadcrumbItem: FC<BreadcrumbExtendedProps> = ({ isCurrentPage, prevItems, url, title, index }) => {
	// const { getDisclosureProps, getButtonProps, isOpen, onClose } = useDisclosure()
	// const ref = React.useRef<HTMLLIElement>(null);
	// const buttonProps = getButtonProps();
	// const disclosureProps = getDisclosureProps();
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const isLargeViewport = useBreakpointValue({ base: false, sm: true }, { ssr: true });
	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

	// useOutsideClick({
	// 	ref: ref,
	// 	handler: () => onClose(),
	// })

	// const hasChildren = menuItems?.length;
	//const showSub = showChildren && menuItems?.length;

	// const focusInCurrentTarget = ({ relatedTarget }: FocusEvent<HTMLLIElement>) => {
	// 	if (relatedTarget === null) return false;
	// 	let node = relatedTarget.parentNode;
	//
	// 	while (node !== null) {
	// 		if (node === ref.current) return true;
	// 		node = node.parentNode;
	// 	}
	//
	// 	return false;
	// }

	//close sub nav if focus outside
	// const onBlur = (e: FocusEvent<HTMLLIElement>) => {
	// 	if (!focusInCurrentTarget(e)) {
	// 		onClose();
	// 	}
	// }

	return (
		<>
			{isLargeViewport &&
				<ChakraBreadcrumbItem
					isCurrentPage={isCurrentPage}
					className="breadcrumb__item"
					{...(isCurrentPage ? { noOfLines: 1 } : { flexShrink: 0 })}>
					{isCurrentPage ? (
						<BreadcrumbLink href={url}>
							{title}
						</BreadcrumbLink>
					) : (
						<>
							{url ?
								<>
									<BreadcrumbLink
										as={Link}
										to={url}
										aria-label={title}
										onClick={() => pushDataLayer(() => {
											prevItems.pop();

											return {
												event: "GAevent",
												event_type: "click",
												event_name: "breadcrumb",
												type: isLgBreakpointOrAbove ? "desktop" : "mobile",
												text: title,
												url,
												breadcrumb: prevItems.map(x => x.title).join(" > ")
											};
										})}>
										<Flex h="breadCrumbHeight" alignItems="center">
											{index === 0
												? <Box className="breadcrumb__aramcoA"><AramcoA fill="currentColor"/></Box>
												: <>{title}</>
											}
										</Flex>
									</BreadcrumbLink>
									<Icon
										role="presentation"
										variant="Next"
										w="icons.sm"
										ms="3"
										transform={isRtl ? "scale(-1)" : ""}
									/>
								</>
								:
								<Box as="span" className="chakra-breadcrumb__link" ms="3">
									{title}
								</Box>
							}
						</>
					)}
					{/*{showSub &&
					<>
						<Button {...buttonProps} className="breadcrumb__trigger">
							<Icon
								variant={isOpen ? "Up" : "Down"}
								w="icons.md"
								marginInlineEnd="0"
							/>
						</Button>
						<Box {...disclosureProps} className="breadcrumb__dropdown">
							{menuItems?.filter(x => x.url).map((breadCrumbItemChild, index) =>
								<Link
									key={`breadcrumb_link_${index}`}
									to={breadCrumbItemChild.url!}
									variant="dropdown"
									size="md"
									lineHeight="1rem"
									dataLayer={() => {
										return {
											event: "GAevent",
											event_type: "click",
											event_name: "breadcrumb",
											type: isLgBreakpointOrAbove ? "desktop" : "mobile",
											text: title,
											url: breadCrumbItemChild.url,
											breadcrumb: prevItems.map(x => x.title).join(" > ")
										};
									}}>
									{breadCrumbItemChild.title}
								</Link>
							)}
						</Box>
					</>
				}*/}
				</ChakraBreadcrumbItem>
			}
		</>
	)
};