/* eslint-disable @typescript-eslint/no-explicit-any, no-restricted-imports */
import {
	RouteData as SitecoreRouteData,
	useSitecoreContext as defaultUseSitecoreContext,
	LayoutServiceContext as DefaultLayoutServiceContext,
	WithSitecoreContextOptions
} from "@sitecore-jss/sitecore-jss-react";
import { CustomContext } from "./generated-types";

export type RouteData<TContextFields = any> = Omit<SitecoreRouteData, "fields"> & {
	fields?: TContextFields;
}

export type SitecoreContextValue<TContextFields = any> = DefaultLayoutServiceContext & {
	route?: RouteData<TContextFields>;
	itemId: string;
	custom: CustomContext;
}

export type UseSitecoreContext<TContextFields = any> = Omit<ReturnType<typeof defaultUseSitecoreContext>, "sitecoreContext"> & {
	sitecoreContext: SitecoreContextValue<TContextFields>;
};

export function useSitecoreContext<TContextFields>(options?: WithSitecoreContextOptions) {
	return defaultUseSitecoreContext(options) as UseSitecoreContext<TContextFields>;
}