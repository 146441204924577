import React, { FC, useState } from "react";
import { ColorTheme } from "./color-themes/default-color-theme";

// eslint-disable-next-line
export const ColorThemeContext = React.createContext<[ColorTheme, React.Dispatch<React.SetStateAction<ColorTheme>>, string | null]>(null as any);

type ColorThemeProviderProps = {
	colorTheme: ColorTheme;
	componentName: string | null;
	children: React.ReactNode;
}

export const ColorThemeProvider: FC<ColorThemeProviderProps> = ({ colorTheme, children, componentName }) => {
	const [theme, setTheme] = useState<ColorTheme>(colorTheme);

	return (
		<ColorThemeContext.Provider value={[theme, setTheme, componentName]}>
			{children}
		</ColorThemeContext.Provider>
	);
};