//Breadcrumb has a variant styling when used on-top of modules with background images. Look for parent class breadcrumb--on-top
import { ColorTheme } from "~/foundation/Theme";

export const BreadCrumbStyling = (theme: ColorTheme, centeredHeader: boolean) => ({
	'sx': {
		display: ["none", null, "block"],
		'.breadcrumb--on-top &': {
			position: "absolute",
			w: "100%",
			zIndex: "1",
			bg: "transparent",
			'.content-wrapper': {
				py: ["2", null, null, null, "4"]
			},
			'.breadcrumb': {
				color: 'white',
				'.chakra-breadcrumb__link': {
					color: 'white',
					'&[aria-current="page"]': {
						color: "white",
					},
					_hover: {
						color: "primary.aramcoBlue"
					},
				},
				// '.breadcrumb__trigger': {
				// 	color: 'white',
				// 	_hover: {
				// 		color: "primary.aramcoBlue"
				// 	},
				// },
				".breadcrumb__aramcoA": {
					color: "white",
					_hover: {
						color: "primary.aramcoBlue"
					},
				},
			},
		},
		'.breadcrumb': {
			position: "relative",
			py: "2",
			ms: "-4",
			color: theme.colors.breadcrumb.linkColor,
			'.chakra-breadcrumb__list': {
				justifyContent: centeredHeader ? "center" : "start"
			},
			'.breadcrumb__item': {
				position: "relative",
				'&--active': {
					color: theme.colors.breadcrumb.activeColor
				},
			},
			'.chakra-breadcrumb__link': {
				fontFamily: 'body',
				fontSize: 'xs',
				fontWeight: '400',
				lineHeight: "var(--chakra-sizes-breadCrumbHeight)",
				letterSpacing: '0.02em',
				color: theme.colors.breadcrumb.linkColor,
				ps: '1',
				pe: '1',
				my: "1",
				ms: "3",
				_hover: {
					color: theme.colors.breadcrumb.activeColor,
					textDecoration: "none"
				},
				'&[aria-current="page"]': {
					color: theme.colors.breadcrumb.linkColorCurrentPage,
					// ps: '1',
					// ms: "0",
					pointerEvents: "none",
				},
			},
			".breadcrumb__aramcoA": {
				color: theme.colors.breadcrumb.aramcoA,
				_hover: {
					color: theme.colors.breadcrumb.activeColor
				}
			},
			// '.breadcrumb__trigger': {
			// 	h: "breadCrumbHeight",
			// 	bg: "transparent",
			// 	ps: "1",
			// 	pe: "1",
			// 	me: "2",
			// 	color: theme.colors.breadcrumb.iconColor,
			// 	'svg': {
			// 		fill: "currentColor"
			// 	},
			// 	'_hover': {
			// 		color: theme.colors.breadcrumb.activeColor,
			// 		bg: "transparent"
			// 	},
			// 	'_focus': {
			// 		color: theme.colors.breadcrumb.activeColor,
			// 		bg: "transparent",
			// 	}
			// },
			// '.breadcrumb__dropdown': {
			// 	position: "absolute",
			// 	top: "100%",
			// 	left: "insetStart",
			// 	width: "max-content",
			// 	minW: "100%",
			// 	maxW: "14rem",
			// 	bg: "primary.lightGrey",
			// 	borderRadius: "md",
			// 	p: "1",
			// }
		},
	}
})