import React, { useEffect, useState } from "react";
import { AnimatePresence } from 'framer-motion'
import { Box } from "@chakra-ui/react";
import { MotionBox } from "~/foundation/Framer/MotionBox";

type CursorProps = {
	x: number,
	y: number,
	useCustomCursor: boolean,
	active: boolean,
	delay: number,
	bg: string,
}

export const Cursor = (props: CursorProps) => {
	const cursorSize = 186;
	const [isVisible, setIsVisible] = useState(props.useCustomCursor);
	const [mousePosition, setMousePosition] = useState({
		x: props.x,
		y: props.y,
	})

	const cursorVariants = {
		initial: {
			opacity: 0,
			scale: 0,
		},
		follow: {
			opacity: .1,
			scale: 1,
			left: mousePosition.x - cursorSize / 2,
			top: mousePosition.y - cursorSize / 2,
		},
		active: {
			transition: {
				duration: props.delay / 1000,
			},
			opacity: .2,
			scale: 8,
			left: mousePosition.x - cursorSize / 2,
			top: mousePosition.y - cursorSize / 2,
		},
		exit: {
			opacity: 0,
			scale: 0,
		}
	}

	useEffect(() => {
		setMousePosition({
			x: props.x,
			y: props.y,
		})

		setIsVisible(props.useCustomCursor)

	}, [props]);

	return (
		<AnimatePresence>
			{isVisible && (
				<MotionBox
					key="cursor"
					h="186px" w="186px"
					position="absolute"
					zIndex="2"
					pointerEvents="none"
					left={mousePosition.x - cursorSize / 2}
					top={mousePosition.y - cursorSize / 2}
					initial={cursorVariants.initial}
					animate={props.active ? cursorVariants.active : cursorVariants.follow}
					exit={cursorVariants.exit}
				>
					<Box w="100%" h="100%" bg={props.bg} borderRadius={cursorSize / 2} />
				</MotionBox>
			)
			}
		</AnimatePresence>
	)
}