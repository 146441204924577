/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Box, Flex, Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { Icon, IconType } from "../Icon";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { LinkSize, LinkVariant } from "~/foundation/Theme/components/Link";
import { LinkFieldValue, useSitecoreContext } from "~/foundation/Jss";
import LinkIconWrapper from "./LinkIconWrapper";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";

function getLinkMargin(sizes: LinkSize | undefined) {
	if (!sizes) {
		return null;
	}

	function getSize(size: string | null) {
		if (!size) {
			return null;
		}

		if (size === "sm") {
			return "3";
		}
		if (size === "md") {
			return "4";
		}
		if (size === "lg") {
			return "5";
		}
		//not in use
		if (size === "xl") {
			return "6";
		}

		return null;
	}

	if (Array.isArray(sizes)) {
		return sizes.map(getSize);
	}

	return getSize(sizes);
}

export type LinkProps = ChakraLinkProps & {
	to: RouterLinkProps["to"];
	variant?: LinkVariant;
	size?: LinkSize;
	iconType?: IconType;
	ariaLabel?: string;
	linkLabel?: string;
	linkValue?: LinkFieldValue;
	target?: string;
	className?: string;
	rel?: string;
	children?: RouterLinkProps["children"];
	dataLayer?: () => GTMDataLayer;
}

// eslint-disable-next-line
export const Link: FC<LinkProps> = ({
	to,
	variant,
	size,
	iconType,
	linkValue,
	ariaLabel,
	target,
	className,
	rel,
	children,
	dataLayer,
	...rest
}) => {

	// const [aramcoLinkBlue] = useToken("colors", ["primary.aramcoLinkBlue"])
	// const [iconColor, setIconColor] = useState("inherit");
	const ref = React.createRef<HTMLAnchorElement>();
	const { sitecoreContext } = useSitecoreContext();
	const rtl = sitecoreContext.custom.settings.isRtl;

	let iconSize: string | (string | null)[];
	if (size && Array.isArray(size)) {
		iconSize = size.map(size => size ? `icons.${size}` : null);
	} else {
		iconSize = `icons.${size}`;
	}

	// useEffect(() => {
	// 	if (ref?.current && getComputedStyle(ref.current).color === aramcoLinkBlue) {
	// 		setIconColor("primary.aramcoBlue");
	// 	}
	// }, []);

	return (
		<ChakraLink
			as={RouterLink}
			to={to}
			variant={variant}
			size={size}
			aria-label={ariaLabel}
			target={target}
			className={className}
			rel={rel}
			ref={ref}
			{...dataLayer && {
				onClick: () => {
					pushDataLayer(dataLayer);
				}
			}}
			{...rest}
		>
			{variant === "secondary" &&
				<LinkIconWrapper type={iconType} linkValue={linkValue} w={iconSize} rtl={rtl} me={getLinkMargin(size) || "4"} /*color={iconColor}*/ />
			}
			{children &&
				<span>{children}</span>
			}
			{variant === "primary" &&
				<Box className="icon-container" ms={getLinkMargin(size) || "4"} /*color={iconColor}*/>
					<LinkIconWrapper type={iconType} linkValue={linkValue} w={iconSize} rtl={rtl}/>
				</Box>
			}
			{(iconType && variant === "dropdown") &&
				<Flex className="icon-container" /*color={iconColor}*/>
					<Icon
						variant={iconType}
						w={iconSize}
						className="icon"
					/>
				</Flex>
			}
		</ChakraLink>
	);
};