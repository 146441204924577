import React, { FC } from 'react';
import { Link } from "~/foundation/Components/Link";
import { LinkSize, LinkVariant } from "~/foundation/Theme/components/Link";
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react'; // eslint-disable-line no-restricted-imports
import { useSitecoreContext } from "..";
import type { LinkProps as SitecoreLinkProps, LinkFieldValue as SitecoreLinkFieldValue } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import type { Optional } from "utility-types";
import { Icon, IconType } from "~/foundation/Components/Icon";
import { Box, Flex, Link as ChakraLink } from "@chakra-ui/react";
import LinkIconWrapper from "~/foundation/Components/Link/LinkIconWrapper";

export type LinkFieldValue = SitecoreLinkFieldValue & {
	id?: string;
	linkType: "external" | "internal" | "media";
	isMediaLink?: boolean;
	mediaSize?: number;
	mediaExtension?: string;
};

export type LinkProps = Optional<SitecoreLinkProps, 'field'> & {
	variant?: LinkVariant;
	size?: LinkSize;
	iconType?: IconType;
	color?: string;
	ariaLabel?: string;
	dataLayer?: () => GTMDataLayer;
}

/** React component that turns Sitecore link values that start with / into react-router route links */
const RoutableSitecoreLink: FC<LinkProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	const editable = props.editable ?? true;

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	const value = props.field?.value as LinkFieldValue | undefined;
	const href = value?.href;
	const hasValidHref = props.field && props.field.value && href;
	const isEditing = editable && (props.field?.editableFirstPart || props.field?.editableLastPart);
	const rtl = sitecoreContext.custom.settings.isRtl;

	let iconSize: string | (string | null)[];
	if (props.size && Array.isArray(props.size)) {
		iconSize = props.size.map(size => size ? `icons.${size}` : null);
	} else {
		iconSize = `icons.${props.size}`;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { variant, size, iconType, ariaLabel, color, ...sitecoreProps } = props;

	return (
		<ChakraLink
			as="span"
			variant={props.variant}
			size={props.size}
			color={props.color}
			data-group>
			{hasValidHref && !isEditing
				? (
					<>
						<Link
							to={href}
							{...(value?.target && { target: value.target })}
							className={value?.class}
							variant={props.variant}
							size={props.size}
							iconType={props.iconType}
							linkValue={value}
							{...(props.ariaLabel && { ariaLabel: props.ariaLabel })}
							color={props.color}
							dataLayer={props.dataLayer}
							{...(!href?.startsWith('/') && { rel: "noopener" })}
						>
							{props.children || value?.text || href || ""}
						</Link>
					</>
				)
				: (
					<>
						{props.variant === "secondary" &&
							<LinkIconWrapper type={props.iconType} linkValue={value} w={iconSize} rtl={rtl} me="5"/>
						}
						{/* eslint-disable-next-line */}
						{/* @ts-ignore */}
						<SitecoreLink {...(sitecoreProps)} />
						{props.variant === "primary" &&
							<Box className="icon-container" ms="5">
								<LinkIconWrapper type={props.iconType} linkValue={value} w={iconSize} rtl={rtl}/>
							</Box>
						}
						{variant === "dropdown" &&
							<Flex className="icon-container">
								<Icon
									variant="Down"
									w={iconSize}
									className="icon"
								/>
							</Flex>
						}
					</>
				)
			}
		</ChakraLink>
	);
};



export default RoutableSitecoreLink;