import React from "react";
import { Flex, SkipNavLink } from "@chakra-ui/react";
import { useTranslation } from "~/foundation/Dictionary";
import LinkIconWrapper from "~/foundation/Components/Link/LinkIconWrapper";
import { NavigationDictionary } from "../dictionary";
import { useSitecoreContext } from "~/foundation/Jss";

export const SkipToContent = () => {
	const { sitecoreContext } = useSitecoreContext();
	const [t] = useTranslation<NavigationDictionary>();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return <SkipNavLink
		bg="primary.aramcoLinkBlue"
		color="primary.white"
		transform={isRtl ? "translateX(100%)" : "translateX(-100%)"}
		height="auto"
		width="auto"
		py="3"
		px="5"
		zIndex="9"
		transitionDuration=".5s"
		transitionProperty="transform"
		transitionTimingFunction="aramcoCubic"
		sx={{
			clip: "none"
		}}
		_focus={{
			transform: "translateX(0%)"
		}}
		aria-label={t("navigation.skipToContent")}
	>
		<Flex>
			<LinkIconWrapper type="ArrowRight" rtl={isRtl} w={["icons.md", null, "icons.lg"]} me="5"/>
			<span>{t("navigation.skipToContent")}</span>
		</Flex>
	</SkipNavLink>;
};