import React from "react";
import { forwardRef, useStyleConfig, BoxProps, ThemingProps, Box } from "@chakra-ui/react";

export type ExtendedBoxProps = BoxProps & ThemingProps;

export const ExtendedBox = forwardRef<ExtendedBoxProps, "div">((props, ref) => {
	const { variant, ...rest } = props;
	const styles = useStyleConfig("ExtendedBox", { variant });

	return <Box ref={ref} __css={styles} {...rest} />;
});