import { getLocalStorage, removeLocalStorage, setLocalStorage } from "~/foundation/LocalStorage";
export { Suitcase } from "./Suitcase";
export { SuitcaseStore, SuitcaseContext } from "./SuitcaseStore";

export type SuitcaseItem = {
	name?: string;
	url: string;
	size?: string;
	type?: string;
}

const localStorageKey = "suitcase";
const suitcaseExpireTime = 1000 * 60 * 60 * 24;

export const getSuitcase = (): SuitcaseItem[] => {
	const currentSuitcase = getLocalStorage<SuitcaseItem[]>(localStorageKey);
	if (!currentSuitcase) {
		return [];
	}

	if (!Array.isArray(currentSuitcase)) {
		clearSuitcase();
		return [];
	}

	return currentSuitcase;
};

export const updateSuitcase = (items: SuitcaseItem[]): void => {
	setLocalStorage(localStorageKey, items, suitcaseExpireTime);
};

export const clearSuitcase = (): void => {
	removeLocalStorage(localStorageKey);
};
